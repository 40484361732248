// /src/RestaurantOfficeUse/services/menuItemService.js
import axiosInstance from '../../api/axiosInstance'; // Assuming axiosInstance is configured for API requests
import { getToken } from '../../utils/authUtils';

export const createMenuItems = async (menuItemData) => {
  const token = getToken();
  console.log("MenuItemData: ", menuItemData);
  try {
    const response = await axiosInstance.post(
      '/Menu',
      menuItemData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error('Error creating Menu Item:', error);
    throw error;
  }
};


// Add other methods if needed


export const fetchMenuItem = async (id) => {
  const token = getToken();
  try {
    const response = await axiosInstance.get(`/Menu/${id}`, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
    return response.data; // Return the response data
  } catch (error) {
    console.error('Error fetching Menu Item:', error);
    throw error; // Re-throw the error to be handled by the calling function
  }
};

export const fetchMenuItemsPagination = async (page, pageSize, searchTerm) => {
  const token = getToken();
  try {
    const response = await axiosInstance.get(`/Menu/pagination`, {
      params: {
        page,
        pageSize,
        searchTerm
      },
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
    return {
      items: response.data.items,
      totalPages: response.data.totalPages,
    };
  } catch (error) {
    console.error('Error fetching Menu Items:', error);
    throw error; // Re-throw the error to be handled by the calling function
  }
};

export const fetchMenuItemsByRestaurantPagination = async (page, pageSize, searchTerm) => {
  const token = getToken();
  try {
    const response = await axiosInstance.get(`/Menu/PaginationRestaurant`, {
      params: {
        page,
        pageSize,
        searchTerm
      },
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
    return {
      items: response.data.items,
      totalPages: response.data.totalPages,
    };
  } catch (error) {
    console.error('Error fetching Menu Items:', error);
    throw error; // Re-throw the error to be handled by the calling function
  }
};

export const updateMenuItem = async (id, updatedData) => {
  const token = getToken();
  try {
    const response = await axiosInstance.put(
      `/Menu/${id}`,
      updatedData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error('Error updating Menu Item:', error);
    throw error; // Re-throw the error to be handled by the calling function
  }
};

export const deleteMenuItem = async (id) => {
  const token = getToken();
  try {
    const response = await axiosInstance.delete(`/Menu/${id}`, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error deleting Menu Item:', error);
    throw error; // Re-throw the error to be handled by the calling function
  }
};

// src/utils/tokenValidityChecker.js
import { decodeToken, getToken } from './authUtils';

export const isTokenValid = () => {
  const token = getToken();
  if (!token) return false;

  try {
    const decodedToken = decodeToken(token);
    const now = Date.now() / 1000;
    return decodedToken.exp > now;
  } catch (error) {
    return false;
  }
};

export const getTokenExpiryTime = () => {
  const token = getToken();
  if (!token) return 0;

  try {
    const decodedToken = decodeToken(token);
    return decodedToken.exp * 1000;
  } catch (error) {
    return 0;
  }
};

import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useAuth } from '../../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import { FaUser, FaLock } from 'react-icons/fa';
import './LoginPage.css';

const validationSchema = Yup.object({
    username: Yup.string().email('Invalid email address').required('Email is required'),
    password: Yup.string().required('Password is required'),
});

const LoginPage = () => {
    const { login } = useAuth();
    const navigate = useNavigate();

    const formik = useFormik({
        initialValues: {
            username: '',
            password: '',
        },
        validationSchema,
        onSubmit: async (values) => {
            try {
                await login(values.username, values.password);
                navigate('/RestaurantOfficeUse');
            } catch (error) {
                formik.setErrors({ submit: 'Invalid credentials, please try again' });
            }
        },
    });

    return (
        <section className="login-section">
            <div className="login-container">
                <div className="login-card">
                    <h2>Login</h2>
                    {formik.errors.submit && <p className="login-error-message">{formik.errors.submit}</p>}
                    <form onSubmit={formik.handleSubmit}>
                        <div className="login-form-group">
                            <label htmlFor="username" className="login-label">
                                <FaUser className="login-icon" /> Username:
                            </label>
                            <input
                                type="email"
                                id="username"
                                name="username"
                                className={`login-input ${formik.touched.username && formik.errors.username ? 'login-input-error' : ''}`}
                                value={formik.values.username}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            />
                            {formik.touched.username && formik.errors.username ? (
                                <div className="login-error-message">{formik.errors.username}</div>
                            ) : null}
                        </div>
                        <div className="login-form-group">
                            <label htmlFor="password" className="login-label">
                                <FaLock className="login-icon" /> Password:
                            </label>
                            <input
                                type="password"
                                id="password"
                                name="password"
                                className={`login-input ${formik.touched.password && formik.errors.password ? 'login-input-error' : ''}`}
                                value={formik.values.password}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            />
                            {formik.touched.password && formik.errors.password ? (
                                <div className="login-error-message">{formik.errors.password}</div>
                            ) : null}
                        </div>
                        <div className="login-remember-me">
                            <input type="checkbox" id="rememberMe" name="rememberMe" />
                            <label htmlFor="rememberMe" className="login-remember-me-label"> Remember me</label>
                        </div>
                        <button type="submit" className="login-submit-button">Login</button>
                        <div className="login-additional-options">
                            <a href="/forgot-password" className="login-forgot-password">Forgot password?</a>
                        </div>
                    </form>
                </div>
            </div>
        </section>
    );
};

export default LoginPage;

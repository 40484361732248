// src/contexts/AuthContext.js
import React, { createContext, useState, useContext, useEffect } from 'react';
import {
  getToken,
  getUsername,
  getUserRoles,
  setUsername,
  setUserRoles,
  clearAuthData,
  decodeToken
} from '../utils/authUtils';
import { login, logout, refreshToken } from '../services/authService';
import { startTokenMonitor, stopTokenMonitor } from '../utils/tokenManager';
import { startInactivityMonitor, stopInactivityMonitor } from '../utils/inactivityTracker';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(!!getToken());
  const [userRoles, setUserRolesState] = useState(getUserRoles);
  const [username, setUsernameState] = useState(getUsername());

  useEffect(() => {
    const token = getToken();
    if (token) {
      try {
        const decodedToken = decodeToken(token);
        const roles = decodedToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'] || [];
        const Uname = decodedToken['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name'] || "";
        setUsername(Uname);
        setUserRoles(roles);
        setIsLoggedIn(true);
        setUserRolesState(roles);
        setUsernameState(Uname);
      } catch (error) {
        console.error('Failed to decode token:', error);
        setIsLoggedIn(false);
        setUserRolesState([]);
        setUsernameState(null);
      }
    }
  }, []);

  useEffect(() => {
    if (isLoggedIn) {
      startTokenMonitor();
      startInactivityMonitor(900000, handleLogout); // 15 minutes
    } else {
      stopTokenMonitor();
      stopInactivityMonitor();
    }
  }, [isLoggedIn]);

  const handleLogin = async (username, password) => {
    try {
      const response = await login(username, password);
      const { accessToken } = response;
      const decodedToken = decodeToken(accessToken);
      const roles = decodedToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'] || [];
      const Uname = decodedToken['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name'] || "";
      setUsername(Uname);
      setUserRoles(roles);
      setIsLoggedIn(true);
      setUserRolesState(roles);
      setUsernameState(Uname);
      startTokenMonitor();
      startInactivityMonitor(900000, handleLogout); // 15 minutes
    } catch (error) {
      console.error('Login failed', error);
      throw error;
    }
  };

  const handleLogout = async () => {
    try {
      await logout();
      clearAuthData();
      setIsLoggedIn(false);
      setUserRoles([]);
      setUsernameState(null);
      stopTokenMonitor();
      stopInactivityMonitor();
    } catch (error) {
      console.error('Logout failed', error);
      throw error;
    }
  };

  return (
    <AuthContext.Provider value={{ isLoggedIn, userRoles, username, login: handleLogin, logout: handleLogout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);

import React, { useEffect, useState } from 'react';
import axios from 'axios'; // Assuming you use axios for the API call

const DiscountItems = () => {
  const [items, setItems] = useState([]);

  useEffect(() => {
    const fetchDiscountItems = async () => {
      try {
        const response = await axios.get('http://108.181.191.13:5157/api/menu'); // Replace with your API endpoint
        setItems(response.data);
      } catch (error) {
        console.error('Error fetching discount items:', error);
      }
    };

    fetchDiscountItems();
  }, []);

  return (
    <section className="py-0">
      <div className="container">
        <div className="row h-100 gx-2 mt-7">
          {items.map((item, index) => (
            <div className="col-sm-6 col-lg-3 mb-3 mb-md-0 h-100 pb-4" key={index}>
              <div className="card card-span h-100">
                <div className="position-relative">
                  <img className="img-fluid rounded-3 w-100" src={item.imageUrl} alt={item.name} />
                  <div className="card-actions">
                    <div className="badge badge-foodwagon bg-primary p-4">
                      <div className="d-flex flex-between-center">
                        <div className="text-white fs-7">{item.discount}</div>
                        <div className="d-block text-white fs-2">% <br />
                          <div className="fw-normal fs-1 mt-2">Off</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-body px-0">
                  <h5 className="fw-bold text-1000 text-truncate">{item.itemName}</h5>
                  <span className="badge bg-soft-danger py-2 px-3">
                    <span className="fs-1 text-danger">{item.daysRemaining} days Remaining</span>
                  </span>
                </div>
                {/* <a className="stretched-link" href={item.link}></a> */}
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default DiscountItems;

// src/utils/authUtils.js
import { jwtDecode } from 'jwt-decode';
import { getMapmyIndiaToken } from '../services/authService';

export const getToken = () => localStorage.getItem('token');
export const getRefreshToken = () => localStorage.getItem('refreshToken');
export const getUsername = () => localStorage.getItem('userName');
export const getUserRoles = () => {
  try {
    const userRoles = localStorage.getItem('userRoles');
    return userRoles ? JSON.parse(userRoles) : [];
  } catch (e) {
    console.error('Failed to parse userRoles from localStorage:', e);
    return [];
  }
};

export const setToken = (token) => localStorage.setItem('token', token);
export const setRefreshToken = (refreshToken) => localStorage.setItem('refreshToken', refreshToken);
export const setUsername = (username) => localStorage.setItem('userName', username);
export const setUserRoles = (userRoles) => localStorage.setItem('userRoles', JSON.stringify(userRoles));

export const clearAuthData = () => {
  localStorage.removeItem('token');
  localStorage.removeItem('refreshToken');
  localStorage.removeItem('userName');
  localStorage.removeItem('userRoles');
};

export const decodeToken = (token) => {
  try {
    return jwtDecode(token);
  } catch (error) {
    console.error('Failed to decode token:', error);
    throw new Error('Invalid token');
  }
};

export const getStoredMapmyIndiaToken = async () => {
  const storedToken = localStorage.getItem('mapmyIndiaToken');
  const expiryTime = localStorage.getItem('mapmyIndiaTokenExpiry');
  
  const currentTime = new Date().getTime();
  
  if (storedToken && expiryTime && currentTime < expiryTime) {
    // Return the stored token if it is not expired
    return storedToken;
  }

  // If the token is expired or not present, fetch a new one
  return await getMapmyIndiaToken();
};


import React from "react";
const BestDeals = () => {
    return (
        <div>
            <section className="pb-5 pt-8">

                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="card card-span mb-3 shadow-lg">
                                <div className="card-body py-0">
                                    <div className="row justify-content-center">
                                        <div className="col-md-5 col-xl-7 col-xxl-8 g-0 order-0 order-md-1"><img className="img-fluid w-100 fit-cover h-100 rounded-top rounded-md-end rounded-md-top-0" src="assets/img/gallery/crispy-sandwiches.png" alt="..." /></div>
                                        <div className="col-md-7 col-xl-5 col-xxl-4 p-4 p-lg-5">
                                            <h1 className="card-title mt-xl-5 mb-4">Best deals <span className="text-primary"> Crispy Sandwiches</span></h1>
                                            <p className="fs-1">Enjoy the large size of sandwiches. Complete your meal with the perfect slice of sandwiches.</p>
                                            <div className="d-grid bottom-0"><a className="btn btn-lg btn-primary mt-xl-6" href="#!">PROCEED TO ORDER<i className="fas fa-chevron-right ms-2"></i></a></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- end of .container--> */}

            </section>
            {/* <!-- <section> close ============================--> */}

            { /*<!-- <section> begin ============================--> */}
            <section className="py-0">

                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="card card-span mb-3 shadow-lg">
                                <div className="card-body py-0">
                                    <div className="row justify-content-center">
                                        <div className="col-md-5 col-xl-7 col-xxl-8 g-0 order-md-0"><img className="img-fluid w-100 fit-cover h-100 rounded-top rounded-md-start rounded-md-top-0" src="assets/img/gallery/fried-chicken.png" alt="..." /></div>
                                        <div className="col-md-7 col-xl-5 col-xxl-4 p-4 p-lg-5">
                                            <h1 className="card-title mt-xl-5 mb-4">Celebrate parties with <span className="text-primary">Fried Chicken</span></h1>
                                            <p className="fs-1">Get the best fried chicken smeared with a lip smacking lemon chili flavor. Check out best deals for fried chicken.</p>
                                            <div className="d-grid bottom-0"><a className="btn btn-lg btn-primary mt-xl-6" href="#!">PROCEED TO ORDER<i className="fas fa-chevron-right ms-2"></i></a></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- end of .container--> */}

            </section>
            { /* <!-- <section> close ============================--> */}

            { /* <!-- <section> begin ============================--> */}
            <section className="pt-5">

                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="card card-span mb-3 shadow-lg">
                                <div className="card-body py-0">
                                    <div className="row justify-content-center">
                                        <div className="col-md-5 col-xl-7 col-xxl-8 g-0 order-0 order-md-1"><img className="img-fluid w-100 fit-cover h-100 rounded-top rounded-md-end rounded-md-top-0" src="assets/img/gallery/pizza.png" alt="..." /></div>
                                        <div className="col-md-7 col-xl-5 col-xxl-4 p-4 p-lg-5">
                                            <h1 className="card-title mt-xl-5 mb-4">Wanna eat hot & <span className="text-primary">spicy Pizza?</span></h1>
                                            <p className="fs-1">Pair up with a friend and enjoy the hot and crispy pizza pops. Try it with the best deals.</p>
                                            <div className="d-grid bottom-0"><a className="btn btn-lg btn-primary mt-xl-6" href="#!">PROCEED TO ORDER<i className="fas fa-chevron-right ms-2"></i></a></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- end of .container--> */}

            </section>
            { /* <!-- <section> close ============================--> */}
            { /*<!-- ============================================--> */}


            <section className="py-0">
                <div className="bg-holder" style={{ backgroundImage:"url('assets/img/gallery/cta-two-bg.png')",backgroundPosition:'center',backgroundSize:'cover' }} >
                </div>
                {/* <!--/.bg-holder--> */}

                <div className="container">
                    <div className="row flex-center">
                        <div className="col-xxl-9 py-7 text-center">
                            <h1 className="fw-bold mb-4 text-white fs-6">Are you ready to order <br />with the best deals? </h1><a className="btn btn-danger" href="#"> PROCEED TO ORDER<i className="fas fa-chevron-right ms-2"></i></a>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default BestDeals;
// src/utils/reverseGeocode.js

export const reverseGeocode = async (latitude, longitude, token) => {
    try {
      const response = await fetch(`https://apis.mapmyindia.com/advancedmaps/v1/${token}/rev_geocode?lat=${latitude}&lng=${longitude}`);
      const data = await response.json();
      if (data && data.results && data.results.length > 0) {
        return data.results[0].formatted_address;
      }
      return '';
    } catch (error) {
      console.error('Reverse geocoding failed:', error);
      return '';
    }
  };
  
import React from 'react';
import { createRoot } from 'react-dom/client';
import './assets/css/theme.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

// Import required scripts
// Import Bootstrap CSS
//import 'bootstrap/dist/css/bootstrap.min.css';

// Import Bootstrap JS and its dependencies
// import './assets/js/popper.min.js';
import 'bootstrap/dist/js/bootstrap.min.js';
// Import required scripts
import '@popperjs/core';
// Import Font Awesome
import '@fortawesome/fontawesome-free/js/all.min.js';
// import './assets/js/bootstrap-navbar.js';
// Import custom theme JS
import './assets/js/theme.js'; // Adjust path as per your project structure


const container = document.getElementById('root');
const root = createRoot(container);
root.render(<App />);

// ReactDOM.render(<App />, document.getElementById('root'));


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// // src/components/Map/MapmyIndiaMap.js

// import React, { useEffect } from 'react';

// const MapmyIndiaMap = ({ center, zoom }) => {
//   useEffect(() => {
//     const loadMap = () => {
//       new window.MapmyIndia.Map('map', {
//         center: center || [28.61, 77.23], // Default to New Delhi
//         zoom: zoom || 10,
//         zoomControl: true,
//         hybrid: true,
//       });
//     };

//     if (!window.MapmyIndia) {
//       const script = document.createElement('script');
//       script.src = `https://apis.mapmyindia.com/advancedmaps/v1/your_api_key/map_load?v=1.5`;
//       script.onload = loadMap;
//       document.body.appendChild(script);
//     } else {
//       loadMap();
//     }
//   }, [center, zoom]);

//   return <div id="map" style={{ height: '400px', width: '100%' }}></div>;
// };

// export default MapmyIndiaMap;
// src/components/MapmyIndiaMap.js

// import React, { useEffect, useRef } from 'react';
// import { geocodeAddress } from '../../services/mapService';

// const MapmyIndiaMap = ({ address }) => {
//   const mapRef = useRef(null);

//   useEffect(() => {
//     if (address) {
//       geocodeAddress(address).then((location) => {
//         if (location && location.latitude && location.longitude) {
//           // Initialize the map
//           const map = new window.MapmyIndia.Map(mapRef.current, {
//             center: [location.latitude, location.longitude],
//             zoom: 15,
//           });

//           // Add marker
//           new window.MapmyIndia.Marker({
//             map,
//             position: [location.latitude, location.longitude],
//             title: address,
//           });
//         }
//       }).catch((error) => {
//         console.error('Error rendering map:', error);
//       });
//     }
//   }, [address]);

//   return <div ref={mapRef} style={{ width: '100%', height: '300px' }} />;
// };

// export default MapmyIndiaMap;

// /src/components/MapComponent.js
// import React, { useEffect, useRef } from 'react';

// const MapComponent = ({ accessToken, onLocationSelect }) => {
//   const mapContainerRef = useRef(null);

//   useEffect(() => {
//     if (window.Mm) {
//       const map = new window.Mm.Map(mapContainerRef.current, {
//         center: [28.6448, 77.216721],
//         zoom: 10,
//         accessToken,
//       });

//       map.on('click', (event) => {
//         const { latLng } = event;
//         const latitude = latLng.lat();
//         const longitude = latLng.lng();
//         if (onLocationSelect) {
//           onLocationSelect({ latitude, longitude });
//         }
//         new window.Mm.Marker([latitude, longitude]).addTo(map);
//       });
//     }
//   }, [accessToken, onLocationSelect]);

//   return <div ref={mapContainerRef} style={{ height: '300px', width: '100%' }} />;
// };

// export default MapComponent;

// /src/components/Map/MapComponent.js
// /src/components/Map/MapComponent.js
// /src/components/Map/MapComponent.js
import './MapComponent.css';
// import React, { useEffect, useState } from 'react';
// import MarkerIcon from './MarkerIcon'; // Import your marker icon component
// import React, { useEffect, useState } from 'react';

// const MapComponent = ({ accessToken, onLocationSelect }) => {
//   const [map, setMap] = useState(null);
//   const [marker, setMarker] = useState(null);

//   useEffect(() => {
//     if (accessToken && !map) {
//       const script = document.createElement('script');
//       script.src = `https://apis.mappls.com/advancedmaps/api/${accessToken}/map_sdk?v=3.0&layer=vector`;
//       script.async = true;
//       script.onload = () => {
//         // Initialize the map with default center location (Chennai)
//         const mapInstance = new window.mappls.Map('map', {
//           center: { lat: 13.0827, lng: 80.2707 }, // Chennai coordinates
//           zoom: 12,
//         });
//         setMap(mapInstance);

//         // Create and add a draggable marker at default location (Chennai)
//         const defaultMarker = new window.mappls.Marker({
//           map: mapInstance,
//           position: { lat: 13.0827, lng: 80.2707 }, // Chennai coordinates
//           draggable: true,
//           icon: 'https://upload.wikimedia.org/wikipedia/commons/thumb/4/4f/Map_marker_icon_%28A%29.svg/1200px-Map_marker_icon_%28A%29.svg.png', // Example icon
//         });
//         setMarker(defaultMarker);

//         // Add event listener for marker drag events
//         defaultMarker.on('dragend', (event) => {
//           const position = event.target.getPosition(); // Get position from event
//           console.log(`Dragged - Latitude: ${position.lat}, Longitude: ${position.lng}`); // Debug log
//           onLocationSelect({ latitude: position.lat, longitude: position.lng });
//         });

//         // Add event listener for map click events
//         mapInstance.on('click', (event) => {
//           const latLng = event.latlng || event.latlngs; // Handle different event formats
//           if (latLng) {
//             const { lat, lng } = latLng;
//             if (marker) {
//               marker.setPosition({ lat, lng });
//             } else {
//               const newMarker = new window.mappls.Marker({
//                 map: mapInstance,
//                 position: { lat, lng },
//                 draggable: true,
//                 icon: 'https://www.svgrepo.com/show/376955/map-marker.svg', // Example icon
//               });
//               newMarker.on('dragend', (event) => {
//                 const position = event.target.getPosition(); // Get position from event
//                 console.log(`Dragged - Latitude: ${position.lat}, Longitude: ${position.lng}`); // Debug log
//                 onLocationSelect({ latitude: position.lat, longitude: position.lng });
//               });
//               setMarker(newMarker);
//             }
//             onLocationSelect({ latitude: lat, longitude: lng }); // Send coordinates on map click
//           }
//         });
//       };
//       document.head.appendChild(script);
//     }
//   }, [accessToken, map, marker, onLocationSelect]);

//   return <div id="map" style={{ height: '400px', width: '100%' }}></div>;
// };

// export default MapComponent;
// /src/components/Map/MapmyIndiaMap.js
// import React, { useEffect } from 'react';
// import MarkerIcon from './MarkerIcon';
// import React, { useEffect, useState } from 'react';
// import { getStoredMapmyIndiaToken } from '../../utils/authUtils'; 
// import { getStoredMapmyIndiaToken } from '../../utils/authUtils'; 
// import React, { useEffect, useState } from 'react';
// import PropTypes from 'prop-types';

// const MapComponent = ({ onLocationSelect, initialLocation, accessToken }) => {
//   const [map, setMap] = useState(null);
//   const [marker, setMarker] = useState(null);

//   useEffect(() => {
//     const loadMap = async () => {
//       const accessToken = await getStoredMapmyIndiaToken();
//       const script = document.createElement('script');
//       script.src = `https://apis.mappls.com/advancedmaps/api/${accessToken}/map_sdk?layer=vector&v=3.0&callback=initMap`;
//       script.async = true;
//       script.defer = true;
//       script.onload = () => {
//         window.initMap = initMap;
//       };
//       document.body.appendChild(script);

//       return () => {
//         document.body.removeChild(script);
//       };
//     };

//     loadMap();
//   }, [accessToken]);

//   const initMap = () => {
//     const mapInstance = new window.mappls.Map('map', {
//       center: [13.0827, 80.2707], // Default to Chennai
//       zoomControl: true,
//       location: true
//     });

//     // Set the initial position for the marker
//     const initialPosition = {
//       lat: initialLocation?.latitude || 13.0827,
//       lng: initialLocation?.longitude || 80.2707
//     };

//     // Create a new marker or update the existing one
//     if (marker) {
//       marker.setPosition(initialPosition);
//       mapInstance.setCenter(initialPosition);
//     } else {
//       const newMarker = new window.mappls.Marker({
//         map: mapInstance,
//         position: initialPosition,
//         draggable: true,
//         popupHtml: 'Drag me!'
//       });

//       newMarker.on('dragend', (event) => {
//         const latLng = event.target._lngLat;
//         if (latLng && typeof onLocationSelect === 'function') {
//           const latitude = latLng.lat;
//           const longitude = latLng.lng;
//           onLocationSelect({ latitude, longitude });
//         } else {
//           console.error('onLocationSelect is not a function');
//         }
//       });

//       setMarker(newMarker);
//     }

//     setMap(mapInstance);
//   };

//   // Update marker position if the initialLocation changes
//   useEffect(() => {
//     if (map && marker && initialLocation) {
//       const newPosition = {
//         lat: parseFloat(initialLocation.latitude),
//         lng: parseFloat(initialLocation.longitude)
//       };
//       marker.setPosition(newPosition);
//       map.setCenter(newPosition);
//     }
//   }, [initialLocation, map, marker]);

//   return <div id="map" style={{ height: '300px', width: '100%' }}></div>;
// };

// MapComponent.propTypes = {
//   onLocationSelect: PropTypes.func.isRequired,
//   initialLocation: PropTypes.shape({
//     latitude: PropTypes.number.isRequired,
//     longitude: PropTypes.number.isRequired
//   }),
//   accessToken: PropTypes.string.isRequired
// };

// MapComponent.defaultProps = {
//   initialLocation: { latitude: 13.0827, longitude: 80.2707 }
// };

// export default MapComponent;
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { reverseGeocode } from '../../utils/reverseGeocode'; // Imported utility function
import { getStoredMapmyIndiaToken } from '../../utils/authUtils';

const MapComponent = ({ onLocationSelect, initialLocation = { latitude: 13.0827, longitude: 80.2707 }, accessToken }) => {
  const [map, setMap] = useState(null);
  const [marker, setMarker] = useState(null);
  const [searchInput, setSearchInput] = useState('');
  const [token, setAccessToken] = useState('');

  useEffect(() => {
    const loadMap = async () => {
      const token = accessToken || await getStoredMapmyIndiaToken();
      setAccessToken(token);

      if (!window.mappls) {
        const script = document.createElement('script');
        script.src = `https://apis.mappls.com/advancedmaps/api/${token}/map_sdk?layer=vector&v=3.0&callback=initMap`;
        script.async = true;
        script.defer = true;
        script.onload = () => {
          window.initMap = initMap;
        };
        document.body.appendChild(script);

        return () => {
          document.body.removeChild(script);
        };
      } else {
        initMap();
      }
    };

    loadMap();
  }, [accessToken]);

  const initMap = () => {
    const validLatitude = !isNaN(initialLocation.latitude) ? initialLocation.latitude : 13.0827;
    const validLongitude = !isNaN(initialLocation.longitude) ? initialLocation.longitude : 80.2707;

    const mapInstance = new window.mappls.Map('map', {
      center: [validLatitude, validLongitude],
      zoomControl: true,
      location: true
    });

    const newMarker = new window.mappls.Marker({
      map: mapInstance,
      position: { lat: validLatitude, lng: validLongitude },
      draggable: true,
      popupHtml: 'Drag me!'
    });

    newMarker.on('dragend', async (event) => {
      const latLng = event.target._lngLat;
      if (latLng) {
        const latitude = latLng.lat;
        const longitude = latLng.lng;
        const address = await reverseGeocode(latitude, longitude, token);
        setSearchInput(address);
        onLocationSelect({ latitude, longitude });
      }
    });

    mapInstance.on('click', async (event) => {
      const latLng = event.latlng || event.latLng;
      if (latLng) {
        const latitude = latLng.lat();
        const longitude = latLng.lng();
        newMarker.setPosition({ lat: latitude, lng: longitude });
        const address = await reverseGeocode(latitude, longitude, token);
        setSearchInput(address);
        onLocationSelect({ latitude, longitude });
      }
    });

    setMarker(newMarker);
    setMap(mapInstance);
  };

  useEffect(() => {
    if (map && marker && initialLocation) {
      const newPosition = {
        lat: parseFloat(initialLocation.latitude),
        lng: parseFloat(initialLocation.longitude)
      };
      marker.setPosition(newPosition);
      map.setCenter(newPosition);
      reverseGeocode(newPosition.lat, newPosition.lng, token).then(setSearchInput);
    }
  }, [initialLocation, map, marker]);

  return (
    <div>
      <input
        type="text"
        value={searchInput}
        onChange={(e) => setSearchInput(e.target.value)}
        placeholder="Enter address or drag the marker"
        className="form-control"
      />
      <div id="map" style={{ height: '300px', width: '100%' }}></div>
    </div>
  );
};

MapComponent.propTypes = {
  onLocationSelect: PropTypes.func.isRequired,
  initialLocation: PropTypes.shape({
    latitude: PropTypes.number,
    longitude: PropTypes.number
  }),
  accessToken: PropTypes.string
};

export default MapComponent;

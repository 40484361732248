// src/components/Notification.js
import React, { useEffect, useState } from 'react';
import './Notification.css'; // Import the general CSS file

const Notification = ({ message, type, onClose }) => {
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(false);
      onClose();
    }, 5000); // Hide notification after 5 seconds

    return () => clearTimeout(timer); // Cleanup on component unmount
  }, [onClose]);

  if (!isVisible) return null; // Do not render if not visible

  return (
    <div className={`notification ${type} show`}>
      {message}
    </div>
  );
};

export default Notification;

import React, { useState, useEffect } from 'react';
import { fetchMenuItemsPagination, deleteMenuItem } from '../../services/menuItemService'; // Ensure you have fetchRestaurants and fetchMenuTypes services
import { fetchRestaurants } from '../../services/restaurantService';
import { fetchMenuTypes } from '../../services/menuTypeService';
import Notification from '../../../components/Notifications/Notification';
import ConfirmDialog from '../../../components/Notifications/ConfirmDialog';
import UpdateMenuItem from './Update';
import '../RestaurantsType/ListRestaurantType.css';

const ListMenuItem = () => {
  const [menuItems, setMenuItems] = useState([]);
  const [restaurants, setRestaurants] = useState([]);
  const [menuTypes, setMenuTypes] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [searchTerm, setSearchTerm] = useState('');
  const [notification, setNotification] = useState({ message: '', type: '' });
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [typeNameToDelete, setTypeNameToDelete] = useState('');
  const [isUpdateOpen, setIsUpdateOpen] = useState(false);
  const [updateTypeId, setUpdateTypeId] = useState(null);

  const fetchData = async () => {
    try {
      const [menuResponse, restaurantResponse, menuTypeResponse] = await Promise.all([
        fetchMenuItemsPagination(currentPage, pageSize, searchTerm),
        fetchRestaurants(), // Fetch all restaurants
        fetchMenuTypes() // Fetch all menu types
      ]);

      if (menuResponse && Array.isArray(menuResponse.items)) {
        setMenuItems(menuResponse.items);
        setTotalPages(menuResponse.totalPages || 0);
      }

      if (restaurantResponse && Array.isArray(restaurantResponse.items)) {
        setRestaurants(restaurantResponse.items);
      }

      if (menuTypeResponse && Array.isArray(menuTypeResponse.items)) {
        setMenuTypes(menuTypeResponse.items);
      }
    } catch (error) {
      let message = 'Failed to load data.';
      if (error.response) {
        message = error.response.data.message || message;
      } else if (error.request) {
        message = 'Network error: Unable to reach server.';
      }
      setNotification({ message, type: 'error' });
    }
  };

  useEffect(() => {
    fetchData();
  }, [currentPage, pageSize, searchTerm]);

  const handlePageChange = (newPage) => {
    if (newPage > 0 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  const handlePageSizeChange = (event) => {
    setPageSize(parseInt(event.target.value, 10));
    setCurrentPage(1); // Reset to first page on page size change
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    setCurrentPage(1); // Reset to first page on search term change
  };

  const handleView = (id) => {
    // Implement view logic here
  };

  const handleUpdate = (id) => {
    setUpdateTypeId(id);
    setIsUpdateOpen(true);
  };

  const handleDeleteClick = (id, typeName) => {
    setDeleteId(id);
    setTypeNameToDelete(typeName);
    setIsDialogOpen(true);
  };

  const handleConfirmDelete = async () => {
    try {
      await deleteMenuItem(deleteId);
      setNotification({
        message: `Menu Item "${typeNameToDelete}" deleted successfully.`,
        type: 'success',
      });
      setIsDialogOpen(false);
      fetchData(); // Refresh the list after deletion
    } catch (error) {
      let message = `Failed to delete Menu Item "${typeNameToDelete}".`;
      if (error.response) {
        message = error.response.data.message || message;
      } else if (error.request) {
        message = 'Network error: Unable to reach server.';
      }
      setNotification({ message, type: 'error' });
      setIsDialogOpen(false);
    }
  };

  const handleNotificationClose = () => {
    setNotification({ message: '', type: '' });
  };

  const handleUpdateClose = () => {
    setIsUpdateOpen(false);
    setUpdateTypeId(null);
  };

  const handleUpdateComplete = () => {
    setIsUpdateOpen(false);
    fetchData(); // Refresh the list after update
  };

  const getRestaurantName = (restaurantID) => {
    const restaurant = restaurants.find(r => r.restaurantID === restaurantID);
    return restaurant ? restaurant.restaurantName : 'Unknown';
  };

  const getMenuTypeName = (menuTypeId) => {
    const menuType = menuTypes.find(mt => mt.menuTypeId === menuTypeId);
    return menuType ? menuType.menuTypeName : 'Unknown';
  };

  return (
    <div className="list-restaurant-type">
      <h2>Menu Items</h2>
      <div className="controls">
        <div className="search-box">
          <input
            type="text"
            placeholder="Search by name"
            value={searchTerm}
            onChange={handleSearchChange}
          />
        </div>
        <select className="page-size-select" onChange={handlePageSizeChange} value={pageSize}>
          <option value={10}>10 items per page</option>
          <option value={20}>20 items per page</option>
          <option value={30}>30 items per page</option>
        </select>
      </div>
      <div className="table-responsive"> {/* Added wrapper for horizontal scrolling */}
      <table className="restaurant-type-table">
          <thead>
            <tr>
              <th>#</th>
              <th>Image</th>
              <th>Name</th>
              <th>Restaurant</th>
              <th>Menu Category</th>
              <th>Price</th>
              <th>Discount</th>
              <th>Is Deliverable</th>
              <th>Is Vegetarian</th>
              <th>Description</th>
              <th>Ingredients</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {menuItems.length > 0 ? (
              menuItems.map((item, index) => (
                <tr key={item.menuId}>
                  <td>{(currentPage - 1) * pageSize + index + 1}</td>
                  <td>
                    <img src={item.imageUrl} alt={item.itemName} className="menu-item-image" />
                  </td>
                  <td>{item.itemName}</td>
                  <td>{getRestaurantName(item.restaurantId)}</td>
                  <td>{getMenuTypeName(item.menuTypeId)}</td>
                  <td>{item.price}</td>
                  <td>{item.discount}</td>
                  <td>{item.isDeliverable ? 'True' : 'False'}</td>
                  <td>{item.isVegetarian ? 'True' : 'False'}</td>
                  <td>{item.description}</td>
                  <td>{item.ingredients.join(', ')}</td>
                  <td>
                    <button className="view" onClick={() => handleView(item.menuId)}>View</button>
                    <button className="update" onClick={() => handleUpdate(item.menuId)}>Update</button>
                    <button className="delete" onClick={() => handleDeleteClick(item.menuId, item.itemName)}>Delete</button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="12">No data available</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <div className="pagination">
        <a
          className={`page-link ${currentPage === 1 ? 'disabled' : ''}`}
          onClick={() => handlePageChange(currentPage - 1)}
        >
          &laquo; Prev
        </a>
        {Array.from({ length: totalPages }, (_, i) => (
          <a
            key={i + 1}
            className={`page-link ${currentPage === i + 1 ? 'active' : ''}`}
            onClick={() => handlePageChange(i + 1)}
          >
            {i + 1}
          </a>
        ))}
        <a
          className={`page-link ${currentPage === totalPages ? 'disabled' : ''}`}
          onClick={() => handlePageChange(currentPage + 1)}
        >
          Next &raquo;
        </a>
      </div>
      {notification.message && (
        <Notification
          message={notification.message}
          type={notification.type}
          onClose={handleNotificationClose}
        />
      )}
      <ConfirmDialog
        isOpen={isDialogOpen}
        onRequestClose={() => setIsDialogOpen(false)}
        onConfirm={handleConfirmDelete}
        typeName={typeNameToDelete}
      />
      <UpdateMenuItem
        isOpen={isUpdateOpen}
        onRequestClose={handleUpdateClose}
        typeId={updateTypeId}
        onUpdated={handleUpdateComplete}
      />
    </div>
  );
};

export default ListMenuItem;

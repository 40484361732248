import React, { useEffect, useState } from 'react';
// import MapComponent from '../Map/MapComponent';
import { getMapmyIndiaToken } from '../../services/authService';
import MapComponent from '../Map/MapmyIndiaMap';
const HeroSection = () => {
  const [mapToken, setMapToken] = useState(null);
  const [location, setLocation] = useState({ latitude: 13.0827, longitude: 80.2707 });

  useEffect(() => {
    const fetchToken = async () => {
      try {
        const token = await getMapmyIndiaToken(); // Fetch the token from your API
        setMapToken(token);
      } catch (error) {
        console.error('Failed to fetch MapmyIndia token:', error);
      }
    };

    fetchToken();
  }, []);

  const handleLocationSelect = (location) => {
    setLocation(location);
    // Handle the location selection as needed
  };

  return (
    <section className="py-5 overflow-hidden bg-primary" id="home">
      <div className="container">
        <div className="row flex-center">
          <div className="col-md-5 col-lg-6 order-0 order-md-1 mt-8 mt-md-0">
            <a className="img-landing-banner" href="#!">
              <img className="img-fluid" src="assets/img/illustrations/palak_panner.jpg" alt="Full-Meals" />
            </a>
          </div>
          <div className="col-md-7 col-lg-6 py-8 text-md-start text-center">
            <h1 className="display-1 fs-md-5 fs-lg-6 fs-xl-8 text-light">Are you starving?</h1>
            <h1 className="text-800 mb-5 fs-4">Within a few clicks, find meals that <br className="d-none d-xxl-block" />are accessible near you</h1>
            <div className="card w-xxl-75">
              <div className="card-body">
                <nav>
                  <MapComponent 
                    initialLocation={location}
                    onLocationSelect={handleLocationSelect}
                    accessToken={mapToken}
                  />
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HeroSection;

import React from 'react';
import HeroSection from './components/Home/HeroSection';
import DiscountItems from './components/Home/DiscountItems';
import HowItWorks from './components/Home/HowItWorks';
import PopularItems from './components/Home/PopularItems';
import FeaturedRestaurants from './components/Home/FeaturedRestaurants';
import SearchbyFood from './components/Home/SearchbyFood';
import MobileAppMarketing from './components/Home/MobileAppMarketing';
import BestDeals from './components/BestDeals';


const Home = () => {
    return (
        <div className="main" id="top">
            <HeroSection />
            <DiscountItems />
            <HowItWorks />
            <PopularItems />
            <FeaturedRestaurants />
            <SearchbyFood />
            {/* <MobileAppMarketing /> */}
            <BestDeals />
        </div>
    );
};

export default Home;
import React, { useState, useEffect } from 'react';
import { fetchMenuItem, updateMenuItem } from '../../services/menuItemService';
import { fetchRestaurants } from '../../services/restaurantService';
import { fetchMenuTypes } from '../../services/menuTypeService';
import { Formik, Form, Field, ErrorMessage, FieldArray } from 'formik';
import * as Yup from 'yup';
import { Switch, FormControlLabel, Button } from '@mui/material';
import '../RestaurantsType/UpdateRestaurantType.css';

const validationSchema = Yup.object({
  restaurantId: Yup.string().required('Select the restaurant you want to assign the menu item'),
  menuTypeId: Yup.string().required('Select the menu category you wish to add menu item'),
  itemName: Yup.string().required('Menu Name is required'),
  description: Yup.string().required('Menu Description is required'),
  price: Yup.number()
    .positive('Price must be a positive number')
    .required('Price is required'),
  discount: Yup.number()
    .min(0, 'Discount cannot be negative')
    .max(100, 'Discount cannot exceed 100')
    .test(
      'is-decimal',
      'Discount must be a decimal with up to 2 decimal places',
      (value) => value === undefined || /^\d+(\.\d{1,2})?$/.test(value.toString())
    )
    .required('Discount is required'),
  isDeliverable: Yup.boolean(),
  isVegetarian: Yup.boolean(),
  imageUrl: Yup.string().url('Invalid URL format').required('Image URL is required'),
  ingredients: Yup.array()
    .of(Yup.string().required('Ingredient cannot be empty'))
    .required('At least one ingredient is required')
    .min(1, 'At least one ingredient is required'),
});

const UpdateMenuItem = ({ isOpen, onRequestClose, typeId, onUpdated }) => {
  const [loading, setLoading] = useState(false);
  const [restaurants, setRestaurants] = useState([]);
  const [menuTypes, setMenuTypes] = useState([]);
  const [initialValues, setInitialValues] = useState({
    restaurantId: '',
    menuTypeId: '',
    itemName: '',
    price: 0,
    discount: 0,
    isDeliverable: true,
    isVegetarian: true,
    imageUrl: '',
    description: '',
    ingredients: [],
  });

  useEffect(() => {
    if (isOpen && typeId) {
      const fetchData = async () => {
        try {
          const data = await fetchMenuItem(typeId);
          const fetchedRestaurants = await fetchRestaurants();
          const fetchedMenuTypes = await fetchMenuTypes();
          setRestaurants(fetchedRestaurants.items);
          setMenuTypes(fetchedMenuTypes.items);
          
          setInitialValues({ 
            restaurantId: data.restaurantId,
            menuTypeId: data.menuTypeId,
            itemName: data.itemName,
            price: data.price,
            discount: data.discount,
            isDeliverable: data.isDeliverable,
            isVegetarian: data.isVegetarian,
            imageUrl: data.imageUrl,
            description: data.description,
            ingredients: data.ingredients,
          });

        } catch (error) {
          console.error('Failed to fetch menu item details.');
        }
      };

      fetchData();
    }
  }, [isOpen, typeId]);

  const handleSubmit = async (values) => {
    setLoading(true);
    try {
      await updateMenuItem(typeId, values);
      onUpdated();
      onRequestClose(); // Close the modal after successful update
    } catch (error) {
      console.error('Failed to update Menu Item.');
    } finally {
      setLoading(false);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="update-restaurant-type">
      <div className="modal-content">
        <h2>Update Menu Item</h2>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ setFieldValue, values }) => (
            <Form>
              <div className="form-group">
                <label htmlFor="imageUrl">Image URL</label>
                <Field id="imageUrl" name="imageUrl" type="text" placeholder="Enter image URL" />
                <ErrorMessage name="imageUrl" component="div" className="error" />
                {values.imageUrl && (
                  <div className="image-preview">
                    <img src={values.imageUrl} alt="Preview" style={{ maxWidth: '200px', maxHeight: '200px' }} />
                  </div>
                )}
              </div>

              <div className="form-group">
                <label htmlFor="itemName">Menu Name</label>
                <Field id="itemName" name="itemName" type="text" placeholder="Enter menu name" />
                <ErrorMessage name="itemName" component="div" className="error" />
              </div>

              <div className="form-group">
                <label htmlFor="restaurantId">Restaurant</label>
                <Field as="select" id="restaurantId" name="restaurantId">
                  <option value="">Select Restaurant</option>
                  {restaurants.map((restaurant) => (
                    <option key={restaurant.restaurantID} value={restaurant.restaurantID}>
                      {restaurant.restaurantName}
                    </option>
                  ))}
                </Field>
                <ErrorMessage name="restaurantId" component="div" className="error" />
              </div>

              <div className="form-group">
                <label htmlFor="menuTypeId">Menu Category</label>
                <Field as="select" id="menuTypeId" name="menuTypeId">
                  <option value="">Select Menu Category</option>
                  {menuTypes.map((type) => (
                    <option key={type.menuTypeId} value={type.menuTypeId}>
                      {type.menuTypeName}
                    </option>
                  ))}
                </Field>
                <ErrorMessage name="menuTypeId" component="div" className="error" />
              </div>

              <div className="form-group">
                <label htmlFor="price">Price</label>
                <Field id="price" name="price" type="number" placeholder="Enter price" />
                <ErrorMessage name="price" component="div" className="error" />
              </div>

              <div className="form-group">
                <label htmlFor="discount">Discount (%)</label>
                <Field id="discount" name="discount" type="number" placeholder="Enter discount" />
                <ErrorMessage name="discount" component="div" className="error" />
              </div>

              <div className="form-group">
                <FormControlLabel
                  control={
                    <Switch
                      checked={values.isDeliverable}
                      onChange={(e) => setFieldValue('isDeliverable', e.target.checked)}
                    />
                  }
                  label="Deliverable"
                />
              </div>

              <div className="form-group">
                <FormControlLabel
                  control={
                    <Switch
                      checked={values.isVegetarian}
                      onChange={(e) => setFieldValue('isVegetarian', e.target.checked)}
                    />
                  }
                  label="Vegetarian"
                />
              </div>

              <div className="form-group">
                <label htmlFor="description">Description</label>
                <Field id="description" name="description" type="text" placeholder="Enter description" />
                <ErrorMessage name="description" component="div" className="error" />
              </div>

              <div className="form-group">
                <label>Ingredients</label>
                <FieldArray name="ingredients">
                  {({ remove, push }) => (
                    <>
                      {values.ingredients.map((ingredient, index) => (
                        <div key={index} className="ingredient-field">
                          <Field
                            name={`ingredients.${index}`}
                            type="text"
                            placeholder="Enter ingredient"
                          />
                          <Button
                            type="button"
                            onClick={() => remove(index)}
                            disabled={values.ingredients.length === 1}
                            variant="outlined"
                            color="error"
                          >
                            Remove
                          </Button>
                          <ErrorMessage
                            name={`ingredients.${index}`}
                            component="div"
                            className="error"
                          />
                        </div>
                      ))}
                      <Button
                        type="button"
                        onClick={() => push('')}
                        variant="contained"
                        color="primary"
                      >
                        Add Ingredient
                      </Button>
                    </>
                  )}
                </FieldArray>
              </div>

              <div className="form-group">
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={loading}
                >
                  {loading ? 'Updating...' : 'Update Menu Item'}
                </Button>
                <Button
                  type="button"
                  onClick={onRequestClose}
                  variant="outlined"
                  color="secondary"
                >
                  Cancel
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default UpdateMenuItem;

import React from 'react';

const CreateRestaurant = () => {
  console.log("Inside restaurants create.js file\n");
  return (
    <div>
      <h1>Create RestaurantOfficeUsers</h1>
      {/* Form for creating a restaurant */}
    </div>
  );
};

export default CreateRestaurant;

import React, { useState, useEffect } from 'react';
import { Formik, Form, Field, ErrorMessage, FieldArray } from 'formik';
import * as Yup from 'yup';
import { createMenuItems, fetchMenuItem } from '../../services/menuItemService';
import { useAuth } from '../../../contexts/AuthContext';
import Notification from '../../../components/Notifications/Notification';
import '../RestaurantsType/CreateRestaurantType.css';
import { fetchRestaurants } from '../../services/restaurantService';
import { fetchMenuTypes } from '../../services/menuTypeService';
import { useParams } from 'react-router-dom';
import { Switch, FormControlLabel, Button } from '@mui/material';

const CreateMenuItem = () => {
  const { id } = useParams();
  const [notification, setNotification] = useState(null);
  const { isLoggedIn } = useAuth();
  const [restaurants, setRestaurants] = useState([]);
  const [menuTypes, setMenuTypes] = useState([]);
  const [initialValues, setInitialValues] = useState({
    restaurantId: '',
    menuTypeId: '',
    itemName: '',
    price: 0,
    discount: 0,
    isDeliverable: true,
    isVegetarian: true,
    imageUrl: '',
    description: '',
    ingredients: [],
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const restaurantsData = await fetchRestaurants(1, 100);
        setRestaurants(restaurantsData.items || []);
        const menuTypesData = await fetchMenuTypes(1, 100);
        setMenuTypes(menuTypesData.items || []);
        if (id) {
          const menuItem = await fetchMenuItem(id);
          setInitialValues({
            restaurantId: menuItem.restaurantId,
            menuTypeId: menuItem.menuTypeId,
            itemName: menuItem.itemName,
            description: menuItem.description,
            price: menuItem.price,
            discount: menuItem.discount,
            isDeliverable: menuItem.isDeliverable,
            isVegetarian: menuItem.isVegetarian,
            imageUrl: menuItem.imageUrl,
            ingredients: menuItem.ingredients || [],
          });
        }
      } catch (error) {
        setNotification({ message: 'Error fetching data.', type: 'error' });
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [id]);

  const validationSchema = Yup.object({
    restaurantId: Yup.string().required('Select the restaurant you want to assign the menu item'),
    menuTypeId: Yup.string().required('Select the menu category you wish to add menu item'),
    itemName: Yup.string().required('Menu Name is required'),
    description: Yup.string().required('Menu Description is required'),
    price: Yup.number()
      .positive('Price must be a positive number')
      .required('Price is required'),
    discount: Yup.number()
      .min(0, 'Discount cannot be negative')
      .max(100, 'Discount cannot exceed 100')
      .test(
        'is-decimal',
        'Discount must be a decimal with up to 2 decimal places',
        (value) => value === undefined || /^\d+(\.\d{1,2})?$/.test(value.toString())
      )
      .required('Discount is required'),
    isDeliverable: Yup.boolean().default(true),
    isVegetarian: Yup.boolean().default(true),
    imageUrl: Yup.string().required('Image URL is required'),
    ingredients: Yup.array()
      .of(Yup.string().required('Ingredient cannot be empty'))
      .required('At least one ingredient is required')
      .min(1, 'At least one ingredient is required'),
  });

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    if (!isLoggedIn) {
      setNotification({ message: 'User is not logged in.', type: 'error' });
      return;
    }

    try {
      await createMenuItems({
        restaurantId: values.restaurantId,
        menuTypeId: values.menuTypeId,
        itemName: values.itemName,
        price: values.price,
        discount: values.discount,
        isDeliverable: values.isDeliverable,
        isVegetarian: values.isVegetarian,
        imageUrl: values.imageUrl,
        description: values.description,
        ingredients: values.ingredients,
      });
      setNotification({ message: 'Menu Item created successfully!', type: 'success' });
      resetForm();
    } catch (error) {
      setNotification({ message: 'Error creating Menu item.', type: 'error' });
      console.error('Error creating Menu item:', error);
    } finally {
      setSubmitting(false);
    }
  };

  const handleNotificationClose = () => {
    setNotification(null);
  };

  return (
    <div className="create-restaurant-type">
      <h2>{id ? 'Update Menu Item' : 'Create Menu Item'}</h2>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting, values, setFieldValue }) => (
          <Form>
            <div className="form-group">
              <label htmlFor="restaurantId">Restaurant</label>
              <Field as="select" name="restaurantId" className="form-control">
                <option value="" label="Select restaurant" />
                {restaurants.map((restaurant) => (
                  <option key={restaurant.restaurantID} value={restaurant.restaurantID}>
                    {restaurant.restaurantName}
                  </option>
                ))}
              </Field>
              <ErrorMessage name="restaurantId" component="div" className="error" />
            </div>
            <div className="form-group">
              <label htmlFor="menuTypeId">Menu Category</label>
              <Field as="select" name="menuTypeId" className="form-control">
                <option value="" label="Select category" />
                {menuTypes.map((type) => (
                  <option key={type.menuTypeId} value={type.menuTypeId}>
                    {type.menuTypeName}
                  </option>
                ))}
              </Field>
              <ErrorMessage name="menuTypeId" component="div" className="error" />
            </div>
            <div className="form-group">
              <label htmlFor="itemName">Menu Name</label>
              <Field
                type="text"
                id="itemName"
                name="itemName"
                className="form-control"
              />
              <ErrorMessage name="itemName" component="div" className="error" />
            </div>
            <div className="form-group">
              <label htmlFor="price">Price</label>
              <Field
                type="number"
                id="price"
                name="price"
                className="form-control"
              />
              <ErrorMessage name="price" component="div" className="error" />
            </div>
            <div className="form-group">
              <label htmlFor="discount">Discount</label>
              <Field
                type="number"
                id="discount"
                name="discount"
                className="form-control"
              />
              <ErrorMessage name="discount" component="div" className="error" />
            </div>
            <div className="form-group">
              <FormControlLabel
                control={
                  <Switch
                    checked={values.isDeliverable}
                    onChange={() => setFieldValue('isDeliverable', !values.isDeliverable)}
                  />
                }
                label="Is Deliverable"
              />
              <ErrorMessage name="isDeliverable" component="div" className="error" />
            </div>
            <div className="form-group">
              <FormControlLabel
                control={
                  <Switch
                    checked={values.isVegetarian}
                    onChange={() => setFieldValue('isVegetarian', !values.isVegetarian)}
                  />
                }
                label="Is Vegetarian"
              />
              <ErrorMessage name="isVegetarian" component="div" className="error" />
            </div>
            <div className="form-group">
              <label htmlFor="imageUrl">Image URL</label>
              <Field
                type="text"
                id="imageUrl"
                name="imageUrl"
                className="form-control"
              />
              <ErrorMessage name="imageUrl" component="div" className="error" />
            </div>
            <div className="form-group">
              <label htmlFor="description">Description</label>
              <Field
                as="textarea"
                id="description"
                name="description"
                className="form-control"
              />
              <ErrorMessage name="description" component="div" className="error" />
            </div>
            <div className="form-group">
              <label htmlFor="ingredients">Ingredients</label>
              <FieldArray name="ingredients">
                {({ push, remove }) => (
                  <div>
                    {values.ingredients.map((ingredient, index) => (
                      <div key={index} className="form-group">
                        <Field
                          type="text"
                          name={`ingredients.${index}`}
                          className="form-control"
                          placeholder={`Ingredient ${index + 1}`}
                        />
                        <Button
                          type="button"
                          onClick={() => remove(index)}
                        >
                          Remove
                        </Button>
                        <ErrorMessage name={`ingredients.${index}`} component="div" className="error" />
                      </div>
                    ))}
                    <Button
                      type="button"
                      onClick={() => push('')}
                    >
                      Add Ingredient
                    </Button>
                  </div>
                )}
              </FieldArray>
            </div>
            <div className="form-group">
              <Button
                type="submit"
                disabled={isSubmitting}
              >
                {id ? 'Update Menu Item' : 'Create Menu Item'}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
      {notification && (
        <Notification
          message={notification.message}
          type={notification.type}
          onClose={handleNotificationClose}
        />
      )}
    </div>
  );
};

export default CreateMenuItem;

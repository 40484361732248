// import axios from 'axios';

// const axiosInstance = axios.create({
//   baseURL: 'http://108.181.191.13:5157/api',
// });

// export default axiosInstance;
// src/api/axiosInstance.js
import axios from 'axios';
import { getToken,setToken,clearAuthData } from '../utils/authUtils';
import { refreshToken } from '../services/authService';
// import { ensureTokenValidity } from '../utils/tokenValidityChecker';

const axiosInstance = axios.create({
  baseURL: 'http://108.181.191.13:5157/api',
  headers: {
    'Content-Type': 'application/json',
  },
});

axiosInstance.interceptors.request.use(
  (config) => {
    const token = getToken();
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      try {
        const tokens = await refreshToken();
        setToken(tokens.accessToken);
        originalRequest.headers['Authorization'] = `Bearer ${tokens.accessToken}`;
        return axiosInstance(originalRequest);
      } catch (refreshError) {
        clearAuthData();
        window.location.href = '/login'; // Redirect to login if refresh fails
        return Promise.reject(refreshError);
      }
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;

// src/utils/tokenRefresher.js
import { refreshToken } from '../services/authService';
import { getTokenExpiryTime } from './tokenValidityChecker';
import { startTokenMonitor } from './tokenManager';

const REFRESH_THRESHOLD = 5 * 60 * 1000; // 5 minutes

export const scheduleTokenRefresh = () => {
  const expiryTime = getTokenExpiryTime();
  const now = Date.now();
  const timeout = expiryTime - now - REFRESH_THRESHOLD;

  setTimeout(async () => {
    try {
      await refreshToken();
      startTokenMonitor(); // Reschedule the token monitor
    } catch (error) {
      console.error('Failed to refresh token:', error);
      // Handle token refresh failure (e.g., redirect to login)
    }
  }, timeout);
};

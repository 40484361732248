import React, { useState, useEffect } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { updateRestaurant, fetchRestaurant } from '../../services/restaurantService';
import { fetchRestaurantTypes } from '../../services/restaurantTypeService';
import Notification from '../../../components/Notifications/Notification';
import MapComponent from '../../../components/Map/MapmyIndiaMap';
import '../RestaurantsType/CreateRestaurantType.css';
import { getMapmyIndiaToken } from '../../../services/authService';

const UpdateRestaurant = ({ isOpen, onRequestClose, typeId, onUpdated }) => {
  const [initialValues, setInitialValues] = useState({
    restaurantName: '',
    restaurantTypeName: '',
    location: ''
  });
  const [restaurantTypes, setRestaurantTypes] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState({ latitude: '', longitude: '' });
  const [mapToken, setMapToken] = useState('');
  const [notification, setNotification] = useState({ message: '', type: '' });

  useEffect(() => {
    if (typeId) {
      // const fetchData = async () => {
      //   try {
      //     const restaurant = await fetchRestaurant(typeId);
      //     setInitialValues({
      //       restaurantName: restaurant.restaurantName,
      //       restaurantTypeName: restaurant.restaurantTypeName,
      //       location: restaurant.location
      //     });
      //     const [latitude, longitude] = restaurant.location.split(',');
      //     setSelectedLocation({ latitude, longitude });

      //     const types = await fetchRestaurantTypes(1, 100); // Adjust if needed
      //     setRestaurantTypes(types.items || []);

      //     const token = await getMapmyIndiaToken();
      //     setMapToken(token);
      //   } catch (error) {
      //     let message = 'Failed to fetch data.';
      //     if (error.response) {
      //       message = error.response.data.message || message;
      //     } else if (error.request) {
      //       message = 'Network error: Unable to reach server.';
      //     }
      //     setNotification({ message, type: 'error' });
      //   }
      // };
      const fetchData = async () => {
        try {
          const restaurant = await fetchRestaurant(typeId);
          // const locationArray = restaurant.location.split(',');
          // const latitude = parseFloat(locationArray[0]);
          // const longitude = parseFloat(locationArray[1]);
          const [latitude, longitude] = restaurant.location.split(',').map(coord => parseFloat(coord.trim()));
      
          if (isNaN(latitude) || isNaN(longitude)) {
            throw new Error('Invalid location data received from the API.');
          }
      
          setInitialValues({
            restaurantName: restaurant.restaurantName,
            restaurantTypeName: restaurant.restaurantTypeName,
            location: restaurant.location
          });
      
          setSelectedLocation({ latitude, longitude });
      
          const types = await fetchRestaurantTypes(1, 100); // Adjust if needed
          setRestaurantTypes(types.items || []);
      
          const token = await getMapmyIndiaToken();
          setMapToken(token);
        } catch (error) {
          let message = 'Failed to fetch data.';
          if (error.response) {
            message = error.response.data.message || message;
          } else if (error.request) {
            message = 'Network error: Unable to reach server.';
          }
          setNotification({ message, type: 'error' });
        }
      };
      

      fetchData();
    }
  }, [typeId]);

  const validationSchema = Yup.object({
    restaurantName: Yup.string()
      .required('Restaurant Name is required')
      .max(50, 'Restaurant Name must be at most 50 characters'),
    restaurantTypeName: Yup.string()
      .required('Restaurant Type is required')
      .max(50, 'Restaurant Type must be at most 50 characters'),
    location: Yup.string().required('Location is required')
  });

  const handleSubmit = async (values, { setSubmitting }) => {
    const locationString = `${selectedLocation.latitude},${selectedLocation.longitude}`;
    try {
      await updateRestaurant({
        id: typeId,
        restaurantName: values.restaurantName,
        restaurantTypeName: values.restaurantTypeName,
        location: locationString
      });
      setNotification({ message: 'Restaurant updated successfully!', type: 'success' });
      onUpdated();
    } catch (error) {
      let message = 'Failed to update restaurant.';
      if (error.response) {
        message = error.response.data.message || message;
      } else if (error.request) {
        message = 'Network error: Unable to reach server.';
      }
      setNotification({ message, type: 'error' });
    } finally {
      setSubmitting(false);
    }
  };

  const handleLocationSelect = (location) => {
    setSelectedLocation(location);
  };

  if (!isOpen) return null;

  return (
    <div className="update-restaurant-type">
      <div className="modal-content">
        <h2>Update Restaurant</h2>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          enableReinitialize
        >
          {({ isSubmitting, setFieldValue }) => (
            <Form>
              <div className="form-group">
                <label htmlFor="restaurantName">Restaurant Name</label>
                <Field
                  type="text"
                  id="restaurantName"
                  name="restaurantName"
                  className="form-control"
                  placeholder="Enter Restaurant Name"
                />
                <ErrorMessage name="restaurantName" component="div" className="error-message" />
              </div>

              <div className="form-group">
                <label htmlFor="restaurantTypeName">Restaurant Type</label>
                <Field as="select" name="restaurantTypeName" className="form-control">
                  <option value="" label="Select type" />
                  {restaurantTypes.map((type) => (
                    <option key={type.restaurantTypeId} value={type.restaurantTypeName}>
                      {type.restaurantTypeName}
                    </option>
                  ))}
                </Field>
                <ErrorMessage name="restaurantTypeName" component="div" className="error-message" />
              </div>

              <div className="form-group">
                <label htmlFor="location">Location</label>
                <MapComponent
                  accessToken={mapToken}
                  initialLocation={selectedLocation}
                  onLocationSelect={(location) => {
                    handleLocationSelect(location);
                    setFieldValue('location', `${location.latitude},${location.longitude}`);
                  }}
                />
                <Field
                  type="text"
                  id="location"
                  name="location"
                  className="form-control"
                  value={`${selectedLocation.latitude},${selectedLocation.longitude}`}
                  readOnly
                />
                <ErrorMessage name="location" component="div" className="error-message" />
              </div>

              <button type="submit" className="btn" disabled={isSubmitting}>
                Update
              </button>
              <button type="button" className="btn cancel" onClick={onRequestClose}>
                Cancel
              </button>
            </Form>
          )}
        </Formik>
      </div>
      {notification.message && (
        <Notification
          message={notification.message}
          type={notification.type}
          onClose={() => setNotification({ message: '', type: '' })}
        />
      )}
    </div>
  );
};

export default UpdateRestaurant;

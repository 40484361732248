import React, { useState } from "react";
import { Link } from 'react-router-dom';
import './Footer.css';

const Footer = () => {
  const [email, setEmail] = useState('');
  const [subscriptionStatus, setSubscriptionStatus] = useState('');

  const handleSubscription = async (e) => {
    e.preventDefault();
    try {
      // Replace with your subscription API endpoint
      const response = await fetch('/api/subscribe', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ email })
      });

      if (response.ok) {
        setSubscriptionStatus('Subscribed successfully!');
      } else {
        setSubscriptionStatus('Subscription failed. Please try again.');
      }
    } catch (error) {
      console.error('Subscription error', error);
      setSubscriptionStatus('An error occurred. Please try again.');
    }
  };

  return (
    <footer className="footer-section py-5 bg-1000">
      <div className="container">
        <div className="row">
          {/* LEGAL Column */}
          <div className="col-12 col-md-4 mb-3">
            <h5 className="lh-lg fw-bold text-white">LEGAL</h5>
            <ul className="list-unstyled">
              <li className="lh-lg">
                <Link to="/terms-and-conditions" className="text-200 text-decoration-none">
                  Terms &amp; Conditions
                </Link>
              </li>
              <li className="lh-lg">
                <Link to="/refund-cancellation" className="text-200 text-decoration-none">
                  Refund &amp; Cancellation
                </Link>
              </li>
              <li className="lh-lg">
                <Link to="/privacy-policy" className="text-200 text-decoration-none">
                  Privacy Policy
                </Link>
              </li>
              <li className="lh-lg">
                <Link to="/cookie-policy" className="text-200 text-decoration-none">
                  Cookie Policy
                </Link>
              </li>
            </ul>
          </div>

          {/* Empty Space Column for alignment */}
          <div className="col-12 col-md-4 mb-3"></div>

          {/* FOLLOW US Column */}
          <div className="col-12 col-md-4 mb-3">
            <h5 className="lh-lg fw-bold text-white">FOLLOW US</h5>
            <div className="social-icons">
              <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer">
                <i className="fab fa-instagram fa-2x text-500 me-2"></i>
              </a>
              <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer">
                <i className="fab fa-facebook fa-2x text-500 me-2"></i>
              </a>
              <a href="https://www.twitter.com" target="_blank" rel="noopener noreferrer">
                <i className="fab fa-twitter fa-2x text-500"></i>
              </a>
            </div>
            <h3 className="text-500 my-4">Receive exclusive offers and discounts in your mailbox</h3>
            <form className="subscription-form" onSubmit={handleSubscription}>
              <div className="input-group">
                <input
                  className="form-control input-box"
                  type="email"
                  placeholder="Enter Email"
                  aria-label="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
                <button className="btn btn-primary" type="submit">Subscribe</button>
              </div>
              {subscriptionStatus && <p className="subscription-status">{subscriptionStatus}</p>}
            </form>
          </div>
        </div>

        <hr className="border border-800" />

        <div className="row flex-center pb-3">
          <div className="col-md-6 text-center text-md-start">
            <p className="text-200">All rights Reserved &copy; Aroma Restaurants and Resorts Ltd., 2024</p>
          </div>
          <div className="col-md-6 text-center text-md-end">
            <p className="text-200">
              Made with&nbsp;
              <svg className="bi bi-suit-heart-fill" xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="#FFB30E" viewBox="0 0 16 16">
                <path d="M4 1c2.21 0 4 1.755 4 3.92C8 2.755 9.79 1 12 1s4 1.755 4 3.92c0 3.263-3.234 4.414-7.608 9.608a.513.513 0 0 1-.784 0C3.234 9.334 0 8.183 0 4.92 0 2.755 1.79 1 4 1z"></path>
              </svg>&nbsp;in&nbsp;
              <a className="text-200 fw-bold" href="https://themewagon.com/" target="_blank" rel="noopener noreferrer">India</a>
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

// /src/restaurantofficeuse/components/restauranttype/ListRestaurantType.js
import React, { useState, useEffect } from 'react';
import { fetchMenuTypes, deleteMenuType } from '../../services/menuTypeService';
import Notification from '../../../components/Notifications/Notification';
import ConfirmDialog from '../../../components/Notifications/ConfirmDialog';
import UpdateMenuType from './Update';
import '../RestaurantsType/ListRestaurantType.css';

const ListMenuCategory = () => {
  const [menuTypes, setMenuTypes] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [searchTerm, setSearchTerm] = useState('');
  const [notification, setNotification] = useState({ message: '', type: '' });
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [typeNameToDelete, setTypeNameToDelete] = useState('');
  const [isUpdateOpen, setIsUpdateOpen] = useState(false);
  const [updateTypeId, setUpdateTypeId] = useState(null);

  const fetchData = async () => {
    try {
      const response = await fetchMenuTypes(currentPage, pageSize, searchTerm);
      // console.log("API Response: ", response);

      if (response && Array.isArray(response.items) && response.items.length > 0) {
        // const [menuTypeList, totalPages] = response.items;
        setMenuTypes(response.items);
        setTotalPages(response.totalPages || 0);
        
      } else {
        console.error("Unexpected API response structure", response);
        setMenuTypes([]);
        setTotalPages(0);
      }
    } catch (error) {
      let message = 'Failed to load menu types.';
      if (error.response) {
        message = error.response.data.message || message;
      } else if (error.request) {
        message = 'Network error: Unable to reach server.';
      }
      setNotification({ message, type: 'error' });
    }
  };

  useEffect(() => {
    fetchData();
  }, [currentPage, pageSize, searchTerm]);

  const handlePageChange = (newPage) => {
    if (newPage > 0 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  const handlePageSizeChange = (event) => {
    setPageSize(parseInt(event.target.value, 10));
    setCurrentPage(1); // Reset to first page on page size change
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    setCurrentPage(1); // Reset to first page on search term change
  };

  const handleView = (id) => {
    // Implement view logic here
  };

  const handleUpdate = (id) => {
    setUpdateTypeId(id);
    setIsUpdateOpen(true);
  };

  const handleDeleteClick = (id, typeName) => {
    setDeleteId(id);
    setTypeNameToDelete(typeName);
    setIsDialogOpen(true);
  };

  const handleConfirmDelete = async () => {
    try {
      await deleteMenuType(deleteId);
      setNotification({
        message: `Menu type "${typeNameToDelete}" deleted successfully.`,
        type: 'success',
      });
      setIsDialogOpen(false);
      fetchData(); // Refresh the list after deletion
    } catch (error) {
      let message = `Failed to delete menu type "${typeNameToDelete}".`;
      if (error.response) {
        message = error.response.data.message || message;
      } else if (error.request) {
        message = 'Network error: Unable to reach server.';
      }
      setNotification({ message, type: 'error' });
      setIsDialogOpen(false);
    }
  };

  const handleNotificationClose = () => {
    setNotification({ message: '', type: '' });
  };

  const handleUpdateClose = () => {
    setIsUpdateOpen(false);
    setUpdateTypeId(null);
  };

  const handleUpdateComplete = () => {
    setIsUpdateOpen(false);
    fetchData(); // Refresh the list after update
  };

  return (
    <div className="list-restaurant-type">
      <h2>Menu Types</h2>
      <div className="controls">
        <div className="search-box">
          <input
            type="text"
            placeholder="Search by name"
            value={searchTerm}
            onChange={handleSearchChange}
          />
        </div>
        <select className="page-size-select" onChange={handlePageSizeChange} value={pageSize}>
          <option value={10}>10 items per page</option>
          <option value={20}>20 items per page</option>
          <option value={30}>30 items per page</option>
        </select>
      </div>
      <table className="restaurant-type-table">
        <thead>
          <tr>
            <th>#</th> {/* Serial Number Column */}
            <th>Type Name</th>
            <th>Description</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {menuTypes.length > 0 ? (
            menuTypes.map((type, index) => (
              <tr key={type.menuTypeId}>
                <td>{(currentPage - 1) * pageSize + index + 1}</td> {/* Serial Number */}
                <td>{type.menuTypeName}</td>
                <td>{type.description}</td> {/* Handle boolean isActive */}
                <td>
                  <button className="view" onClick={() => handleView(type.menuTypeId)}>View</button>
                  <button className="update" onClick={() => handleUpdate(type.menuTypeId)}>Update</button>
                  <button className="delete" onClick={() => handleDeleteClick(type.menuTypeId, type.menuTypeName)}>Delete</button>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="4">No data available</td> {/* Adjust colSpan */}
            </tr>
          )}
        </tbody>
      </table>
      <div className="pagination">
        <a
          className={`page-link ${currentPage === 1 ? 'disabled' : ''}`}
          onClick={() => handlePageChange(currentPage - 1)}
        >
          &laquo; Prev
        </a>
        {Array.from({ length: totalPages }, (_, i) => (
          <a
            key={i + 1}
            className={`page-link ${currentPage === i + 1 ? 'active' : ''}`}
            onClick={() => handlePageChange(i + 1)}
          >
            {i + 1}
          </a>
        ))}
        <a
          className={`page-link ${currentPage === totalPages ? 'disabled' : ''}`}
          onClick={() => handlePageChange(currentPage + 1)}
        >
          Next &raquo;
        </a>
      </div>
      {notification.message && (
        <Notification
          message={notification.message}
          type={notification.type}
          onClose={handleNotificationClose}
        />
      )}
      <ConfirmDialog
        isOpen={isDialogOpen}
        onRequestClose={() => setIsDialogOpen(false)}
        onConfirm={handleConfirmDelete}
        typeName={typeNameToDelete}
      />
      <UpdateMenuType
        isOpen={isUpdateOpen}
        onRequestClose={handleUpdateClose}
        typeId={updateTypeId}
        onUpdated={handleUpdateComplete}
      />
    </div>
  );
};

export default ListMenuCategory;

// src/utils/tokenManager.js
import { isTokenValid } from './tokenValidityChecker';
import { scheduleTokenRefresh } from './tokenRefresher';

export const startTokenMonitor = () => {
  if (isTokenValid()) {
    scheduleTokenRefresh();
  }
};

export const stopTokenMonitor = () => {
  clearTimeout(scheduleTokenRefresh);
};

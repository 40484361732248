// /src/RestaurantOfficeUse/components/RestaurantType/CreateRestaurantType.js
import React, { useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { createMenuType } from '../../services/menuTypeService';
import { useAuth } from '../../../contexts/AuthContext';
import Notification from '../../../components/Notifications/Notification'; // Import Notification component
import '../RestaurantsType/CreateRestaurantType.css';  // Import the CSS file

const CreateMenuCategory = () => {
  const [notification, setNotification] = useState({ message: '', type: '' });
  const { isLoggedIn } = useAuth();
  // const [notification, setNotification] = useState(null);

  const validationSchema = Yup.object({
    menutypename: Yup.string().required('Menu Category is required'),
    description: Yup.string().required('Menu Description is required'),
  });

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    if (!isLoggedIn) {
      console.error('User is not logged in.');
      return;
    }

    try {
      await createMenuType({menutypename: values.menutypename, description: values.description});
      setNotification({ message: 'Menu Type created successfully!', type: 'success' });
      resetForm();
    } catch (error) {
      console.error('Error creating Menu type:', error);
      setNotification({ message: 'Error creating Menu type.', type: 'error' });
    } finally {
      setSubmitting(false);
    }
  };

  const handleNotificationClose = () => {
    setNotification(null);
  };

  return (
    <div className="create-restaurant-type">
      <h2>Create Menu Category</h2>
      <Formik
        initialValues={{ menutypename: '', description: '' }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting }) => (
          <Form>
            <div className="form-group">
              <label htmlFor="MenuTypeName">Menu Category Name</label>
              <Field
                type="text"
                id="menutypename"
                name="menutypename"
                className="form-control"
              />
              <ErrorMessage name="menutypename" component="div" className="error" />
            </div>
            <div className="form-group">
              <label htmlFor="MenuTypeName">Description</label>
              <Field
                type="text"
                id="description"
                name="description"
                className="form-control"
              />
              <ErrorMessage name="description" component="div" className="error" />
            </div>
            <button type="submit" className="btn" disabled={isSubmitting}>
              Create
            </button>
          </Form>
        )}
      </Formik>
      {notification && (
        <Notification
          message={notification.message}
          type={notification.type}
          onClose={handleNotificationClose}
        />
      )}
    </div>
  );
};

export default CreateMenuCategory;

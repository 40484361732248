import React, { useState, useEffect } from 'react';
import { fetchMenuType, updateMenuType } from '../../services/menuTypeService';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import '../RestaurantsType/UpdateRestaurantType.css'; // Import the CSS file

const validationSchema = Yup.object({
  menutypename: Yup.string()
    .required('Type Name is required')
    .min(2, 'Type Name must be at least 2 characters')
    .max(50, 'Type Name cannot exceed 50 characters'),
  description: Yup.string()
    .required('Type Name is required')
    .min(2, 'Type Name must be at least 2 characters')
    .max(100, 'Type Name cannot exceed 50 characters'),
});

const UpdateMenuType = ({ isOpen, onRequestClose, typeId, onUpdated }) => {
  const [initialValues, setInitialValues] = useState({ menutypename: '',description:'' });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (isOpen && typeId) {
      const fetchData = async () => {
        try {
          const data = await fetchMenuType(typeId);
          // console.log("data: ", data);
          setInitialValues({ menutypename: data.menuTypeName, description: data.description });
        } catch (error) {
          console.error('Failed to fetch menu type details.');
        }
      };

      fetchData();
    }
  }, [isOpen, typeId]);

  const handleSubmit = async (values) => {
    setLoading(true);
    try {
      await updateMenuType(typeId, { menutypename: values.menutypename, description: values.description });
      onUpdated();
      onRequestClose(); // Close the modal after successful update
    } catch (error) {
      console.error('Failed to update menu type.');
    } finally {
      setLoading(false);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="update-restaurant-type">
      <div className="modal-content">
        <h2>Update Menu Category</h2>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          <Form>
            <div className="form-group">
              <label htmlFor="menutypename">Category Name</label>
              <Field
                id="menutypename"
                name="menutypename"
                type="text"
                placeholder="Enter type name"
              />
              <ErrorMessage name="menutypename" component="div" className="error" />
            </div>
            <div className="form-group">
              <label htmlFor="description">Description</label>
              <Field
                id="description"
                name="description"
                type="text"
                placeholder="Enter type name"
              />
              <ErrorMessage name="description" component="div" className="error" />
            </div>
            <button type="submit" disabled={loading}>
              {loading ? 'Updating...' : 'Update'}
            </button>
            <button type="button" onClick={onRequestClose}>
              Cancel
            </button>
          </Form>
        </Formik>
      </div>
    </div>
  );
};

export default UpdateMenuType;

import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { AuthProvider, useAuth } from './contexts/AuthContext';
import TermsAndConditions from './TermsAndConditions';
import Layout from './Layout';
import LoginPage from './components/Authentication/LoginPage';
import Home from './Home';
import RestaurantOfficeUseRoutes from './RestaurantOfficeUse/RestaurantOfficeUseRoutes';

const ProtectedRoute = ({ children, roles }) => {  
  const { isLoggedIn, userRoles } = useAuth();
  if (!isLoggedIn) {
    return <Navigate to="/login" />;
  }

  if (roles && !roles.some(role => userRoles.includes(role))) {
    return <Navigate to="/" />;
  }
  return children;
};

const App = () => {
  return (
    <AuthProvider>
      <Router>
        <div className="main" id="top">
          <Routes>
            <Route path="/" element={<Layout />}>
              <Route index element={<Home />} />
              <Route path="login" element={<LoginPage />} />
              <Route path="terms-and-conditions" element={<TermsAndConditions />} />
              <Route path="RestaurantOfficeUse/*" element={
                <ProtectedRoute roles={['SuperAdmin', 'Admin']}>
                  <RestaurantOfficeUseRoutes />
                </ProtectedRoute>
              } />
            </Route>
          </Routes>
        </div>
      </Router>
    </AuthProvider>
  );
};

export default App;

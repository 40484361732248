import React, { useState, useEffect } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { createRestaurant, fetchRestaurant, updateRestaurant } from '../../services/restaurantService';
import { fetchRestaurantTypes } from '../../services/restaurantTypeService';
import { getMapmyIndiaToken } from '../../../services/authService';
import Notification from '../../../components/Notifications/Notification';
import MapComponent from '../../../components/Map/MapmyIndiaMap';
import '../RestaurantsType/CreateRestaurantType.css';
import { useParams } from 'react-router-dom';

const CreateRestaurants = () => {
  const { id } = useParams(); // Get id from URL parameters
  const [notification, setNotification] = useState(null);
  const [restaurantTypes, setRestaurantTypes] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState({ latitude: '', longitude: '' });
  const [mapToken, setMapToken] = useState('');
  const [initialValues, setInitialValues] = useState({
    restaurantName: '',
    restaurantTypeName: '',
    location: ''
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const types = await fetchRestaurantTypes(1, 100); // Adjust if needed
        setRestaurantTypes(types.items || []);
        const token = await getMapmyIndiaToken();
        setMapToken(token);

        if (id) { // Check if id is available
          const restaurant = await fetchRestaurant(id); // Fetch existing restaurant details
          setInitialValues({
            restaurantName: restaurant.restaurantName,
            restaurantTypeName: restaurant.restaurantTypeName,
            location: restaurant.location
          });
          const [latitude, longitude] = restaurant.location.split(',');
          setSelectedLocation({ latitude, longitude });
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [id]);

  const validationSchema = Yup.object({
    restaurantName: Yup.string()
      .required('Restaurant Name is required')
      .max(50, 'Restaurant Name must be at most 50 characters'),
    restaurantTypeName: Yup.string()
      .required('Restaurant Type is required')
      .max(50, 'Restaurant Type must be at most 50 characters'),
    location: Yup.string().required('Location is required')
  });

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    const locationString = `${selectedLocation.latitude},${selectedLocation.longitude}`;
    try {
      if (id) {
        // Assuming you have an updateRestaurant function
        await updateRestaurant({
          id,
          restaurantName: values.restaurantName,
          restaurantTypeName: values.restaurantTypeName,
          location: locationString,
        });
      } else {
        await createRestaurant({
          restaurantName: values.restaurantName,
          restaurantTypeName: values.restaurantTypeName,
          location: locationString,
        });
      }
      setNotification({ message: 'Restaurant saved successfully!', type: 'success' });
      resetForm();
    } catch (error) {
      console.error('Error saving restaurant:', error);
      setNotification({ message: 'Error saving restaurant.', type: 'error' });
    } finally {
      setSubmitting(false);
    }
  };

  const handleNotificationClose = () => {
    setNotification(null);
  };

  const handleLocationSelect = (location) => {
    console.log("location: ", location);
    setSelectedLocation(location);
  };

  return (
    <div className="create-restaurant-type">
      <h2>{id ? 'Update Restaurant' : 'Add Restaurant'}</h2>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize // Ensure form updates when initialValues change
      >
        {({ isSubmitting, setFieldValue }) => (
          <Form>
            <div className="form-group">
              <label htmlFor="restaurantName">Restaurant Name</label>
              <Field
                type="text"
                id="restaurantName"
                name="restaurantName"
                className="form-control"
                placeholder="Enter Restaurant Name"
              />
              <ErrorMessage name="restaurantName" component="div" className="error-message" />
            </div>

            <div className="form-group">
              <label htmlFor="restaurantTypeName">Restaurant Type</label>
              <Field as="select" name="restaurantTypeName" className="form-control">
                <option value="" label="Select type" />
                {restaurantTypes.map((type) => (
                  <option key={type.restaurantTypeId} value={type.restaurantTypeName}>
                    {type.restaurantTypeName}
                  </option>
                ))}
              </Field>
              <ErrorMessage name="restaurantTypeName" component="div" className="error-message" />
            </div>

            <div className="form-group">
              <label htmlFor="location">Location</label>
              <MapComponent
                accessToken={mapToken}
                onLocationSelect={(location) => {
                  handleLocationSelect(location);
                  setFieldValue('location', `${location.latitude},${location.longitude}`);
                }}
              />
              <Field
                type="text"
                id="location"
                name="location"
                className="form-control"
                value={`${selectedLocation.latitude},${selectedLocation.longitude}`}
                readOnly
              />
              <ErrorMessage name="location" component="div" className="error-message" />
            </div>

            <button type="submit" className="btn" disabled={isSubmitting}>
              {id ? 'Update' : 'Add'}
            </button>
          </Form>
        )}
      </Formik>
      {notification && (
        <Notification
          message={notification.message}
          type={notification.type}
          onClose={handleNotificationClose}
        />
      )}
    </div>
  );
};

export default CreateRestaurants;

import React from 'react';

const ListRestaurantOfficeUser = () => {
  return (
    <div>
      <h1>List Restaurant office user</h1>
      {/* Form for creating a restaurant */}
    </div>
  );
};

export default ListRestaurantOfficeUser;

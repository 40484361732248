// /src/RestaurantOfficeUse/components/RestaurantType/CreateRestaurantType.js
import React, { useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { createRestaurantType } from '../../services/restaurantTypeService';
import { useAuth } from '../../../contexts/AuthContext';
import Notification from '../../../components/Notifications/Notification'; // Import Notification component
import './CreateRestaurantType.css';  // Import the CSS file

const CreateRestaurantType = () => {
  const [notification, setNotification] = useState({ message: '', type: '' });
  const { isLoggedIn } = useAuth();
  // const [notification, setNotification] = useState(null);

  const validationSchema = Yup.object({
    restaurantTypeName: Yup.string().required('Restaurant Type Name is required'),
  });

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    if (!isLoggedIn) {
      console.error('User is not logged in.');
      return;
    }

    try {
      await createRestaurantType(values.restaurantTypeName);
      setNotification({ message: 'Restaurant Type created successfully!', type: 'success' });
      resetForm();
    } catch (error) {
      console.error('Error creating restaurant type:', error);
      setNotification({ message: 'Error creating restaurant type.', type: 'error' });
    } finally {
      setSubmitting(false);
    }
  };

  const handleNotificationClose = () => {
    setNotification(null);
  };

  return (
    <div className="create-restaurant-type">
      <h2>Create Restaurant Type</h2>
      <Formik
        initialValues={{ restaurantTypeName: '' }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting }) => (
          <Form>
            <div className="form-group">
              <label htmlFor="restaurantTypeName">Restaurant Type Name</label>
              <Field
                type="text"
                id="restaurantTypeName"
                name="restaurantTypeName"
                className="form-control"
              />
              <ErrorMessage name="restaurantTypeName" component="div" className="error" />
            </div>
            <button type="submit" className="btn" disabled={isSubmitting}>
              Create
            </button>
          </Form>
        )}
      </Formik>
      {notification && (
        <Notification
          message={notification.message}
          type={notification.type}
          onClose={handleNotificationClose}
        />
      )}
    </div>
  );
};

export default CreateRestaurantType;

import React, { useState, useEffect } from 'react';
import { fetchRestaurants, deleteRestaurant } from '../../services/restaurantService';
import Notification from '../../../components/Notifications/Notification';
import ConfirmDialog from '../../../components/Notifications/ConfirmDialog';
import UpdateRestaurant from './Update';
import '../RestaurantsType/ListRestaurantType.css';

const ListRestaurant = () => {
  const [restaurants, setRestaurants] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [searchTerm, setSearchTerm] = useState('');
  const [notification, setNotification] = useState({ message: '', type: '' });
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [nameToDelete, setNameToDelete] = useState('');
  const [isUpdateOpen, setIsUpdateOpen] = useState(false);
  const [updateId, setUpdateId] = useState(null);

  const fetchData = async () => {
    try {
      const response = await fetchRestaurants(currentPage, pageSize, searchTerm);
      if (Array.isArray(response.items) && response.items.length > 0) {
        setRestaurants(response.items);
        setTotalPages(response.totalPages);
      } else {
        setRestaurants([]);
        setTotalPages(0);
      }
    } catch (error) {
      let message = 'Failed to load restaurants.';
      if (error.response) {
        message = error.response.data.message || message;
      } else if (error.request) {
        message = 'Network error: Unable to reach server.';
      }
      setNotification({ message, type: 'error' });
    }
  };

  useEffect(() => {
    fetchData();
  }, [currentPage, pageSize, searchTerm]);

  const handlePageChange = (newPage) => {
    if (newPage > 0 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  const handlePageSizeChange = (event) => {
    setPageSize(parseInt(event.target.value, 10));
    setCurrentPage(1);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    setCurrentPage(1);
  };

  const handleView = (id) => {
    // Implement view logic here
  };

  const handleUpdate = (id) => {
    setUpdateId(id);
    setIsUpdateOpen(true);
  };

  const handleDeleteClick = (id, name) => {
    setDeleteId(id);
    setNameToDelete(name);
    setIsDialogOpen(true);
  };

  const handleConfirmDelete = async () => {
    try {
      await deleteRestaurant(deleteId);
      setNotification({
        message: `Restaurant "${nameToDelete}" deleted successfully.`,
        type: 'success',
      });
      setIsDialogOpen(false);
      fetchData();
    } catch (error) {
      let message = `Failed to delete restaurant "${nameToDelete}".`;
      if (error.response) {
        message = error.response.data.message || message;
      } else if (error.request) {
        message = 'Network error: Unable to reach server.';
      }
      setNotification({ message, type: 'error' });
      setIsDialogOpen(false);
    }
  };

  const handleNotificationClose = () => {
    setNotification({ message: '', type: '' });
  };

  const handleUpdateClose = () => {
    setIsUpdateOpen(false);
    setUpdateId(null);
  };

  const handleUpdateComplete = () => {
    setIsUpdateOpen(false);
    fetchData();
  };

  return (
    <div className="list-restaurant-type">
      <h2>Restaurants</h2>
      <div className="controls">
        <div className="search-box">
          <input
            type="text"
            placeholder="Search by name"
            value={searchTerm}
            onChange={handleSearchChange}
          />
        </div>
        <select className="page-size-select" onChange={handlePageSizeChange} value={pageSize}>
          <option value={10}>10 items per page</option>
          <option value={20}>20 items per page</option>
          <option value={30}>30 items per page</option>
        </select>
      </div>
      <table className="restaurant-type-table">
        <thead>
          <tr>
            <th>#</th>
            <th>Name</th>
            <th>Restaurant Type Name</th>
            <th>Location</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {restaurants.length > 0 ? (
            restaurants.map((restaurant, index) => (
              <tr key={restaurant.restaurantID}>
                <td>{(currentPage - 1) * pageSize + index + 1}</td>
                <td>{restaurant.restaurantName}</td>
                <td>{restaurant.restaurantTypeName}</td>
                <td>{restaurant.location}</td>
                <td>
                  <button className="view" onClick={() => handleView(restaurant.restaurantID)}>View</button>
                  <button className="update" onClick={() => handleUpdate(restaurant.restaurantID)}>Update</button>
                  <button className="delete" onClick={() => handleDeleteClick(restaurant.restaurantID, restaurant.restaurantName)}>Delete</button>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="5">No data available</td>
            </tr>
          )}
        </tbody>
      </table>
      <div className="pagination">
        <a
          className={`page-link ${currentPage === 1 ? 'disabled' : ''}`}
          onClick={() => handlePageChange(currentPage - 1)}
        >
          &laquo; Prev
        </a>
        {Array.from({ length: totalPages }, (_, i) => (
          <a
            key={i + 1}
            className={`page-link ${currentPage === i + 1 ? 'active' : ''}`}
            onClick={() => handlePageChange(i + 1)}
          >
            {i + 1}
          </a>
        ))}
        <a
          className={`page-link ${currentPage >= totalPages ? 'disabled' : ''}`}
          onClick={() => handlePageChange(currentPage + 1)}
        >
          Next &raquo;
        </a>
      </div>
      {notification.message && (
        <Notification
          message={notification.message}
          type={notification.type}
          onClose={handleNotificationClose}
        />
      )}
      <ConfirmDialog
        isOpen={isDialogOpen}
        onRequestClose={() => setIsDialogOpen(false)}
        onConfirm={handleConfirmDelete}
        name={nameToDelete}
      />
      <UpdateRestaurant
        isOpen={isUpdateOpen}
        onRequestClose={handleUpdateClose}
        typeId={updateId}
        onUpdated={handleUpdateComplete}
      />
    </div>
  );
};

export default ListRestaurant;

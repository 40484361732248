import React, { useState } from 'react';
import { Link, Outlet } from 'react-router-dom';
import './components/css/layout_style.css'; // Import custom CSS for layout
import { useAuth } from '../contexts/AuthContext';
// import { useAuth } from '../contexts/AuthContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faPlus,faPlusCircle, faEye, faEdit, faTrash, faTable } from '@fortawesome/free-solid-svg-icons';
import { faUtensils, faListAlt, faHamburger, faUsersCog,faConciergeBell } from '@fortawesome/free-solid-svg-icons';

const Layout = () => {
  const { userRoles } = useAuth();
  const [sidebarOpen, setSidebarOpen] = useState(false);

  if (!userRoles.includes('SuperAdmin') && !userRoles.includes('Admin')) {
    return <p>You do not have permission to access this page.</p>;
  }

  const handleDropdownClick = (e) => {
    const dropdownContent = e.currentTarget.nextElementSibling;
    if (dropdownContent.style.display === "block") {
      dropdownContent.style.display = "none";
    } else {
      dropdownContent.style.display = "block";
    }
  };

  const handleLinkClick = () => {
    setSidebarOpen(false); // Close the sidebar when a link is clicked
  };

  return (
    <div className="layout-wrapper">
      <button className="sidebar-toggle" onClick={() => setSidebarOpen(!sidebarOpen)}>
        <FontAwesomeIcon icon={faBars} />
      </button>
      <div className={`layout-container ${sidebarOpen ? 'sidebar-open' : ''}`}>
        <aside className={`sidebar ${sidebarOpen ? 'open' : ''}`}>
          <h2 className="sidebar-title">Restaurant Office Use</h2>
          <ul className="sidebar-menu">
            <li>
              <span className="dropdown-title" onClick={handleDropdownClick}>
                <FontAwesomeIcon icon={faConciergeBell} /> Restaurants Type
              </span>
              <ul className="dropdown-content">
                <li><Link className="sidebar-link" to="restaurants-type/create" onClick={handleLinkClick}><FontAwesomeIcon icon={faPlusCircle} /> Create</Link></li>
                {/* <li><Link className="sidebar-link" to="restaurants-type/read" onClick={handleLinkClick}><FontAwesomeIcon icon={faEye} /> Read</Link></li>
                <li><Link className="sidebar-link" to="restaurants-type/update" onClick={handleLinkClick}><FontAwesomeIcon icon={faEdit} /> Update</Link></li>
                <li><Link className="sidebar-link" to="restaurants-type/delete" onClick={handleLinkClick}><FontAwesomeIcon icon={faTrash} /> Delete</Link></li> */}
                <li><Link className="sidebar-link" to="restaurants-type/list" onClick={handleLinkClick}><FontAwesomeIcon icon={faListAlt} /> List</Link></li>
              </ul>
            </li>
            <li>
              <span className="dropdown-title" onClick={handleDropdownClick}>
                <FontAwesomeIcon icon={faUtensils} /> Restaurants
              </span>
              <ul className="dropdown-content">
                <li><Link className="sidebar-link" to="restaurants/create" onClick={handleLinkClick}><FontAwesomeIcon icon={faPlusCircle} /> Create</Link></li>
                {/* <li><Link className="sidebar-link" to="restaurants/read" onClick={handleLinkClick}><FontAwesomeIcon icon={faEye} /> Read</Link></li>
                <li><Link className="sidebar-link" to="restaurants/update" onClick={handleLinkClick}><FontAwesomeIcon icon={faEdit} /> Update</Link></li>
                <li><Link className="sidebar-link" to="restaurants/delete" onClick={handleLinkClick}><FontAwesomeIcon icon={faTrash} /> Delete</Link></li> */}
                <li><Link className="sidebar-link" to="restaurants/list" onClick={handleLinkClick}><FontAwesomeIcon icon={faListAlt} /> List</Link></li>
              </ul>
            </li>
            <li>
              <span className="dropdown-title" onClick={handleDropdownClick}>
                <FontAwesomeIcon icon={faListAlt} /> Menu Categories
              </span>
              <ul className="dropdown-content">
                <li><Link className="sidebar-link" to="menu-categories/create" onClick={handleLinkClick}><FontAwesomeIcon icon={faPlusCircle} /> Create</Link></li>
                {/* <li><Link className="sidebar-link" to="menu-categories/read" onClick={handleLinkClick}><FontAwesomeIcon icon={faEye} /> Read</Link></li>
                <li><Link className="sidebar-link" to="menu-categories/update" onClick={handleLinkClick}><FontAwesomeIcon icon={faEdit} /> Update</Link></li>
                <li><Link className="sidebar-link" to="menu-categories/delete" onClick={handleLinkClick}><FontAwesomeIcon icon={faTrash} /> Delete</Link></li> */}
                <li><Link className="sidebar-link" to="menu-categories/list" onClick={handleLinkClick}><FontAwesomeIcon icon={faListAlt} /> List</Link></li>
              </ul>
            </li>
            <li>
              <span className="dropdown-title" onClick={handleDropdownClick}>
                <FontAwesomeIcon icon={faHamburger} /> Menu Items
              </span>
              <ul className="dropdown-content">
                <li><Link className="sidebar-link" to="menu-items/create" onClick={handleLinkClick}><FontAwesomeIcon icon={faPlusCircle} /> Create</Link></li>
                {/* <li><Link className="sidebar-link" to="menu-items/read" onClick={handleLinkClick}><FontAwesomeIcon icon={faEye} /> Read</Link></li>
                <li><Link className="sidebar-link" to="menu-items/update" onClick={handleLinkClick}><FontAwesomeIcon icon={faEdit} /> Update</Link></li>
                <li><Link className="sidebar-link" to="menu-items/delete" onClick={handleLinkClick}><FontAwesomeIcon icon={faTrash} /> Delete</Link></li> */}
                <li><Link className="sidebar-link" to="menu-items/list" onClick={handleLinkClick}><FontAwesomeIcon icon={faListAlt} /> List</Link></li>
              </ul>
            </li>
            <li>
              <span className="dropdown-title" onClick={handleDropdownClick}>
                <FontAwesomeIcon icon={faUsersCog} /> Restaurant Office Users
              </span>
              <ul className="dropdown-content">
                <li><Link className="sidebar-link" to="restaurant-office-users/create" onClick={handleLinkClick}><FontAwesomeIcon icon={faPlusCircle} /> Create</Link></li>
                {/* <li><Link className="sidebar-link" to="restaurant-office-users/read" onClick={handleLinkClick}><FontAwesomeIcon icon={faEye} /> Read</Link></li>
                <li><Link className="sidebar-link" to="restaurant-office-users/update" onClick={handleLinkClick}><FontAwesomeIcon icon={faEdit} /> Update</Link></li>
                <li><Link className="sidebar-link" to="restaurant-office-users/delete" onClick={handleLinkClick}><FontAwesomeIcon icon={faTrash} /> Delete</Link></li> */}
                <li><Link className="sidebar-link" to="restaurant-office-users/list" onClick={handleLinkClick}><FontAwesomeIcon icon={faListAlt} /> List</Link></li>
              </ul>
            </li>
          </ul>
        </aside>
        <main className="main-content">
          <Outlet /> {/* Render nested routes */}
        </main>
      </div>
    </div>
  );
};

export default Layout;

// /src/components/Notifications/ConfirmDialog.js
import React from 'react';
import './ConfirmDialog.css'; // Include custom styles for the dialog

const ConfirmDialog = ({ isOpen, onRequestClose, onConfirm, typeName }) => {
  if (!isOpen) return null;

  return (
    <div className="confirm-dialog-overlay">
      <div className="confirm-dialog">
        <h3>Confirm Deletion</h3>
        <p>Are you sure you want to delete the restaurant type: <strong>{typeName}</strong>?</p>
        <div className="confirm-dialog-actions">
          <button className="confirm-dialog-button confirm" onClick={onConfirm}>Yes, Delete</button>
          <button className="confirm-dialog-button cancel" onClick={onRequestClose}>Cancel</button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmDialog;

// /src/RestaurantOfficeUse/services/MenuTypeService.js
import axiosInstance from '../../api/axiosInstance'; // Assuming axiosInstance is configured for API requests
import { getToken } from '../../utils/authUtils';

export const createMenuType = async (MenuTypeData) => {
  const token = getToken();
  try {
    const response = await axiosInstance.post(
      '/MenuType',
      MenuTypeData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error('Error creating Menu type:', error);
    throw error; // Re-throw the error to be handled by the calling function
  }
};

// export const fetchMenuTypes = async (page, pageSize) => {
//   const token = getToken();
//   const response = await axiosInstance.get(`/MenuType?page=${page}&pageSize=${pageSize}`, {
//     headers: {
//       'Authorization': `Bearer ${token}`,
//       'Content-Type': 'application/json',
//     },
//   });
//   // Assuming the response data is an object with keys as indices
//   const data = Object.values(response.data); // Convert object to array
//   return {
//     items: data,
//     totalPages: Math.ceil(data.length / pageSize), // Calculate total pages based on data length
//   };
//   // return response.data;
// };
// Function to fetch Menu type details by ID (if needed)
export const fetchMenuType = async (id) => {
  const token = getToken();
  try {
    const response = await axiosInstance.get(`/MenuType/${id}`,{
    headers: {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    });
    return response.data; // Return the response data
  } catch (error) {
    console.error('Error fetching Menu type:', error);
    throw error; // Re-throw the error to be handled by the calling function
  }
};
export const fetchMenuTypes = async (page, pageSize, searchTerm) => {
  const token = getToken();
  try {
    const response = await axiosInstance.get(`/MenuType`, {
      params: {
        page,
        pageSize,
        searchTerm
      },
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
    // const data = Object.values(response.data); // Convert object to array
    return {
      items: response.data.items,
      totalPages: response.data.totalPages,//Math.ceil(data.length / pageSize), // Calculate total pages based on data length
    };
  } catch (error) {
    console.error('Error fetching Menu types:', error);
    throw error; // Re-throw the error to be handled by the calling function
  }
  // return response.data;
};

// Function to update a Menu type
export const updateMenuType = async (id,updatedData) => {
  const token = getToken();
  try {
    const response = await axiosInstance.put(
      `/MenuType/${id}`,
      updatedData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error('Error updating Menu type:', error);
    throw error; // Re-throw the error to be handled by the calling function
  }
};
export const deleteMenuType = async (id) => {
  const token = getToken();
  try {
    const response = await axiosInstance.delete(`/MenuType/${id}`, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error deleting Menu type:', error);
    throw error; // Re-throw the error to be handled by the calling function
  }
};

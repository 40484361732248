// src/services/authService.js
import axiosInstance from '../api/axiosInstance';
import {
  getRefreshToken,
  setToken,
  getToken,
  setRefreshToken,
  clearAuthData,
} from '../utils/authUtils';

export const login = async (username, password) => {
  const response = await axiosInstance.post('/RestaurantOfficeUserLogin/login', { username, password });
  const { accessToken, refreshToken } = response.data;
  setToken(accessToken);
  setRefreshToken(refreshToken);
  return response.data;
};

export const logout = async () => {
  try {
    const token = getToken();
    // console.log("Inside logout\n",token);
    await axiosInstance.post('/RestaurantOfficeUserLogin/logout', {}, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      }
    });
    // await axiosInstance.get('/RestaurantOfficeUserLogin/logout');
    clearAuthData();
  } catch (error) {
    console.error('Logout failed:', error);
    throw error;
  }
};

export const refreshToken = async () => {
  try {
    const token = getToken();
    const refreshToken = getRefreshToken();

    if (!token || !refreshToken) {
      throw new Error('Tokens are required');
    }

    const response = await axiosInstance.post('/RestaurantOfficeUserLogin/refresh', {
      accessToken: token,
      refreshToken: refreshToken,
    }, {
      headers: {
        'Authorization': `Bearer ${token}`,
      }
    });

    const { accessToken: newAccessToken, refreshToken: newRefreshToken } = response.data;
    setToken(newAccessToken);
    setRefreshToken(newRefreshToken);

    return response.data;
  } catch (error) {
    console.error('Failed to refresh token:', error);
    throw error; // Ensure errors are properly propagated
  }
};

export const getMapmyIndiaToken = async () => {
  try {
    const token = getToken();
    const response = await axiosInstance.get('/MapmyIndia/token', {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      }
    });

    // Extract the MapmyIndia token and its expiry time from the response
    const mapmyIndiaToken = response.data.access_token;
    const expiresIn = response.data.expires_in; // Assuming response includes expires_in in seconds

    // Calculate the expiration time and store it
    const expiryTime = new Date().getTime() + expiresIn * 1000;

    // Store the token and its expiry time in localStorage
    localStorage.setItem('mapmyIndiaToken', mapmyIndiaToken);
    localStorage.setItem('mapmyIndiaTokenExpiry', expiryTime);

    return mapmyIndiaToken;
  } catch (error) {
    console.error('Failed to fetch MapmyIndia token:', error);
    throw error;
  }
};




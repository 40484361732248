// import React from 'react';
// import { Link } from 'react-router-dom';
// // import 'bootstrap/dist/cs/bootstrap.min.css';

// // const Header = () => {
// //   const headerStyle = {
// //     backgroundColor: '#F9FAFD',
// //     padding: '10px 20px',
// //     color: '#fff'
// //   };
// const logo_container  = () => {
//   display: 'flex',
//   justify-content: center, /* Center the logo */
//   align-items: center, /* Center vertically */
//   padding: 10px /* Add some padding */
// }

// .logo {
//   max-width: 100%; /* Make the logo responsive */
//   height: auto; /* Maintain aspect ratio */
// }


// //   const navItemStyle = {
// //     margin: '0 10px',
// //     textDecoration: 'none',
// //     color: '#FFB30E',
// //     fontSize:'21px',
// //     fontWeight: '1500'
// //   };
// //   // #F9FAFD !important;
// //   // const header_area = {
// //   //   padding: '20px 0',
// //   //   background: '#fff',
// //   //   position: 'absolute',
// //   //   top: '20px',
// //   //   left: '50%',
// //   //   width: '80%',
// //   //   zIndex: '1',
// //   //   WebkitTransform: 'translate(-50%, 0)',
// //   //   MozTransform: 'translate(-50%, 0)',
// //   //   msTransform: 'translate(-50%, 0)',
// //   //   OTransform: 'translate(-50%, 0)',
// //   //   transform: 'translate(-50%, 0)',
// //   //   WebkitBorderRadius: '3px',
// //   //   MozBorderRadius: '3px',
// //   //   msBorderRadius: '3px',
// //   //   OBorderRadius: '3px',
// //   //   borderRadius: '3px',
// //   // };
// //   return (
// //     <header style={headerStyle}>
// //       <nav>
// //         <a href="/home" style={navItemStyle}>Home</a>
// //         <a href="#menu" style={navItemStyle}>Menu</a>
// //         <a href="#contact" style={navItemStyle}>Contact</a>
// //         <a href="/terms-and-conditions" style={navItemStyle}>Terms</a>
// //       </nav>
// //     </header>
// //   );
// //   // return (

// //     // <header className="mx-auto pt-5 pt-lg-0 d-block d-lg-none d-xl-block">
// //     //         <nav>
// //     //           <ul className="navbar-nav me-auto mb-2 mb-lg-0">
// //     //             <li className="nav-item">
// //     //               <a className="nav-link" href="/">Home</a>
// //     //             </li>
// //     //             <li className="nav-item">
// //     //               <a className="nav-link" href="/menu">Menu</a>
// //     //             </li>
// //     //             <li className="nav-item">
// //     //               <a className="nav-link" href="/contact">Contact</a>
// //     //             </li>
// //     //           </ul>
// //     //         </nav>
// //     //       </header>
// //   // );
// //   //   <header style={header_area}>
// //   //       <div class="container">
// //   //           <div class="row">
// //   //               <div class="col-lg-2">
// //   //                   <div class="logo-area">
// //   //                       <a href="index.html"><img src="assets/images/logo/logo.png" alt="logo" /></a>
// //   //                   </div>
// //   //               </div>
// //   //               <div class="col-lg-10">
// //   //                   <div class="custom-navbar">
// //   //                       <span></span>
// //   //                       <span></span>
// //   //                       <span></span>
// //   //                   </div>  
// //   //                   <div class="main-menu">
// //   //                       <ul>
// //   //                           <li class="active"><a href="index.html">home</a></li>
// //   //                           <li><a href="about.html">about</a></li>
// //   //                           <li><a href="menu.html">menu</a></li>
// //   //                           <li><a href="#">blog</a>
// //   //                               <ul class="sub-menu">
// //   //                                   <li><a href="blog-home.html">Blog Home</a></li>
// //   //                                   <li><a href="blog-details.html">Blog Details</a></li>
// //   //                               </ul>
// //   //                           </li>
// //   //                           <li><a href="contact-us.html">contact</a></li>
// //   //                           <li><a href="elements.html">Elements</a></li>
// //   //                       </ul>
// //   //                   </div>
// //   //               </div>
// //   //           </div>
// //   //       </div>
// //   //   </header>
// //   // );
// // };
// const Navbar = () => {
//   return (
//     <nav className="navbar navbar-expand-lg navbar-light bg-light fixed-top" data-navbar-on-scroll="data-navbar-on-scroll">
//       <div className="container">
//         <a className="navbar-brand d-inline-flex" href="/">
//           <img className="d-inline-block" src="assets/img/gallery/logo.svg" alt="logo" />
//           <span className="text-1000 fs-3 fw-bold ms-2 text-gradient">Rasoie</span>
//         </a>
//         <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
//           <span className="navbar-toggler-icon"> </span>
//         </button>
//         <div className="collapse navbar-collapse border-top border-lg-0 my-2 mt-lg-0" id="navbarSupportedContent">
//           <div className="mx-auto pt-5 pt-lg-0 d-block d-lg-none d-xl-block">
//             <p className="mb-0 fw-bold text-lg-center">
//             <span className="fas fa-phone-volume mr-2"></span>&nbsp;
//             <a href="tel: +919591428238">+91 9282 100 000</a>             
//             </p>
//             {/* <Header /> */}
//           </div>

//           <form className="d-flex mt-4 mt-lg-0 ms-lg-auto ms-xl-0">
//             <div className="input-group-icon pe-2">
//               <i className="fas fa-search input-box-icon text-primary"></i>
//               <input className="form-control border-0 input-box bg-100" type="search" placeholder="Search Food" aria-label="Search" />
//             </div>

//             <button className="btn btn-white shadow-warning text-warning" type="submit">
//               <i className="fas fa-user me-2"></i>
//               <Link to="/login">Login</Link>
//             </button>
//           </form>
//         </div>
//       </div>
//     </nav>
//   );
// };

// export default Navbar;

// import React, { useState, useEffect } from 'react';
// import { Link, useNavigate } from 'react-router-dom';
// //import './assets/css/logo_style.css';
// import '../../assets/css/logo_style.css';
// // import styled from 'styled-components';
// const Navbar = () => {
//   const [isLoggedIn, setIsLoggedIn] = useState(false);
//   const [username, setUsername] = useState('');
//   const navigate = useNavigate();

//   useEffect(() => {
//     const token = localStorage.getItem('token');
//     const user = localStorage.getItem('userName');
//     // console.log("tttttttoken: ",token);
//     // console.log("Usernaggggggggggme: ", user);
//     if (token) {
//       setIsLoggedIn(true);
//       setUsername(user);
//     }
//   }, []);

//   const logoContainerStyle = {
//     display: 'flex',
//     justifyContent: 'left', // Center the logo
//     alignItems: 'center', // Center vertically
//     padding: '10px', // Add some padding
// };

// const logoStyle = {
//     width: '20%', // Set a fixed width
//     height: 'auto', // Maintain aspect ratio
// };

//   const handleLogout = () => {
//     localStorage.removeItem('token');
//     localStorage.removeItem('refreshToken');
//     localStorage.removeItem('userName');
//     setIsLoggedIn(false);
//     navigate('/login');
//   };

//   return (
//     <nav className="navbar navbar-expand-lg navbar-light bg-light fixed-top" data-navbar-on-scroll="data-navbar-on-scroll">
//       <div className="container">
//         <a className="navbar-brand d-inline-flex" href="/">
//         {/* <div className="logo-container">
//           <img src="/assets/img/rasoie_logo.jpg" alt="logo" />
//         </div> */}
//           <div style={logoContainerStyle}>
//             <img src="/assets/img/rasoie_logo.jpg" alt="Rasoie Logo" style={logoStyle} />
//         </div>
//           {/* <span className="text-1000 fs-3 fw-bold ms-2 text-gradient">Rasoie</span> */}
//         </a>
//         <button
//           className="navbar-toggler"
//           type="button"
//           data-bs-toggle="collapse"
//           data-bs-target="#navbarSupportedContent"
//           aria-controls="navbarSupportedContent"
//           aria-expanded="false"
//           aria-label="Toggle navigation"
//         >
//           <span className="navbar-toggler-icon"></span>
//         </button>
//         <div className="collapse navbar-collapse border-top border-lg-0 my-2 mt-lg-0" id="navbarSupportedContent">
//           <div className="mx-auto pt-5 pt-lg-0 d-block d-lg-none d-xl-block">
//             <p className="mb-0 fw-bold text-lg-center">
//               <span className="fas fa-phone-volume mr-2"></span>&nbsp;
//               <a href="tel: +919591428238">+91 9282 100 000</a>
//             </p>
//           </div>

//           {isLoggedIn ? (
//             <div className="ms-lg-auto ms-xl-0">
//               <div className="dropdown">
//                 <button className="btn btn-secondary dropdown-toggle" type="button" id="userMenu" data-bs-toggle="dropdown" aria-expanded="false">
//                   {username}
//                 </button>
//                 <ul className="dropdown-menu" aria-labelledby="userMenu">
//                   <li>
//                     <Link className="dropdown-item" to="/profile">Profile</Link>
//                   </li>
//                   <li>
//                     <Link className="dropdown-item" to="/change-password">Change Password</Link>
//                   </li>
//                   <li>
//                     <button className="dropdown-item" onClick={handleLogout}>Logout</button>
//                   </li>
//                 </ul>
//               </div>
//             </div>
//           ) : (
//             <form className="d-flex mt-4 mt-lg-0 ms-lg-auto ms-xl-0">
//               <div className="input-group-icon pe-2">
//                 <i className="fas fa-search input-box-icon text-primary"></i>
//                 <input className="form-control border-0 input-box bg-100" type="search" placeholder="Search Food" aria-label="Search" />
//               </div>
//               <Link className="btn btn-white shadow-warning text-warning" to="/login">
//                 <i className="fas fa-user me-2"></i>Login
//               </Link>
//             </form>
//           )}
//         </div>
//       </div>
//     </nav>
//   );
// };

// export default Navbar;
// import React, { useState, useEffect } from 'react';
// import { Link, useNavigate } from 'react-router-dom';
// import '../../assets/css/logo_style.css';

// const Navbar = () => {
//   const [isLoggedIn, setIsLoggedIn] = useState(false);
//   const [username, setUsername] = useState('');
//   const navigate = useNavigate();

//   useEffect(() => {
//     const token = localStorage.getItem('token');
//     const user = localStorage.getItem('userName');
//     if (token) {
//       setIsLoggedIn(true);
//       setUsername(user);
//     }
//   }, []);

//   // const logoStyle = {
//   //   width: '150px', // Set a fixed width
//   //   height: 'auto', // Maintain aspect ratio
//   // };

//   const handleLogout = () => {
//     localStorage.removeItem('token');
//     localStorage.removeItem('refreshToken');
//     localStorage.removeItem('userName');
//     setIsLoggedIn(false);
//     navigate('/login');
//   };

//   return (
//     <nav className="navbar navbar-expand-lg navbar-light bg-light fixed-top" data-navbar-on-scroll="data-navbar-on-scroll">
//       <div className="container">
//         <a className="navbar-brand d-inline-flex" href="/">
//           <img src="/assets/img/rasoie_logo.jpg" alt="Rasoie Logo" className="logo" />
//           {/* Optionally add a brand name next to the logo */}
//           <span className="text-1000 fs-3 fw-bold ms-2 text-gradient">Rasoie</span>
//         </a>
//         <button
//           className="navbar-toggler"
//           type="button"
//           data-bs-toggle="collapse"
//           data-bs-target="#navbarSupportedContent"
//           aria-controls="navbarSupportedContent"
//           aria-expanded="false"
//           aria-label="Toggle navigation"
//         >
//           <span className="navbar-toggler-icon"></span>
//         </button>
//         <div className="collapse navbar-collapse border-top border-lg-0 my-2 mt-lg-0" id="navbarSupportedContent">
//           <div className="mx-auto pt-5 pt-lg-0 d-block d-lg-none d-xl-block">
//             <p className="mb-0 fw-bold text-lg-center">
//               <span className="fas fa-phone-volume mr-2"></span>&nbsp;
//               <a href="tel: +919591428238">+91 9282 100 000</a>
//             </p>
//           </div>

//           {isLoggedIn ? (
//             <div className="ms-lg-auto ms-xl-0">
//               <div className="dropdown">
//                 <button className="btn btn-secondary dropdown-toggle" type="button" id="userMenu" data-bs-toggle="dropdown" aria-expanded="false">
//                   {username}
//                 </button>
//                 <ul className="dropdown-menu" aria-labelledby="userMenu">
//                   <li>
//                     <Link className="dropdown-item" to="/profile">Profile</Link>
//                   </li>
//                   <li>
//                     <Link className="dropdown-item" to="/change-password">Change Password</Link>
//                   </li>
//                   <li>
//                     <button className="dropdown-item" onClick={handleLogout}>Logout</button>
//                   </li>
//                 </ul>
//               </div>
//             </div>
//           ) : (
//             <form className="d-flex mt-4 mt-lg-0 ms-lg-auto ms-xl-0">
//               <div className="input-group-icon pe-2">
//                 <i className="fas fa-search input-box-icon text-primary"></i>
//                 <input className="form-control border-0 input-box bg-100" type="search" placeholder="Search Food" aria-label="Search" />
//               </div>
//               <Link className="btn btn-white shadow-warning text-warning" to="/login">
//                 <i className="fas fa-user me-2"></i>Login
//               </Link>
//             </form>
//           )}
//         </div>
//       </div>
//     </nav>
//   );
// };

// export default Navbar;
//  data-navbar-on-scroll="data-navbar-on-scroll"

import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import '../../assets/css/logo_style.css';
import { useAuth } from '../../contexts/AuthContext';

const Navbar = () => {
  const { isLoggedIn, username, logout } = useAuth();
  const navigate = useNavigate();

  const handleLogout = () => {
    logout();
    localStorage.removeItem('token');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('userName');
    navigate('/login');
  };

  return (
    <nav className="navbar navbar-expand-lg navbar-custom fixed-top shadow-lg" data-navbar-on-scroll="data-navbar-on-scroll">
      <div className="container">
        <a className="navbar-brand d-inline-flex" href="/">
          <img src="/assets/img/rasoie_logo.jpg" alt="Rasoie Logo" className="logo" />
          <span className="text-primary fs-3 fw-bold ms-2">Rasoie</span>
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <div className="d-flex flex-column flex-lg-row align-items-center w-100">
            <div className="d-flex flex-grow-1 justify-content-center contact-info-container">
              <p className="contact-info mb-0 fw-bold">
                <span className="fas fa-phone-volume contact-icon"></span>&nbsp;
                <a className="contact-link" href="tel:+919591428238">+91 9282 100 000</a>
              </p>
            </div>
            <div className="ms-auto d-flex align-items-center">
              {isLoggedIn ? (
                <div className="dropdown">
                  <button className="btn btn-outline-primary dropdown-toggle" type="button" id="userMenu" data-bs-toggle="dropdown" aria-expanded="false">
                    {username}
                  </button>
                  <ul className="dropdown-menu" aria-labelledby="userMenu">
                    <li>
                      <Link className="dropdown-item" to="/restaurantOfficeUse">Office Use</Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/profile">Profile</Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/change-password">Change Password</Link>
                    </li>
                    <li>
                      <button className="dropdown-item" onClick={handleLogout}>Logout</button>
                    </li>
                  </ul>
                </div>
              ) : (
                <form className="d-flex align-items-center search-form">
                  <div className="input-group-icon pe-2">
                    <i className="fas fa-search input-box-icon"></i>
                    <input className="form-control border-0 input-box bg-light" type="search" placeholder="Search Food" aria-label="Search" />
                  </div>
                  <Link className="btn btn-warning text-dark login-btn ms-2" to="/login">
                    <i className="fas fa-user me-2"></i>Login
                  </Link>
                </form>
              )}
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;




// import React, { useState, useEffect } from 'react';
// import { Link, useNavigate } from 'react-router-dom';

// const Navbar = () => {
//   const [isLoggedIn, setIsLoggedIn] = useState(false);
//   const navigate = useNavigate();

//   useEffect(() => {
//     const token = localStorage.getItem('accessToken');
//     if (token) {
//       setIsLoggedIn(true);
//     }
//   }, []);
//   const username = null;
//   const handleLogout = () => {
//     localStorage.removeItem('accessToken');
//     localStorage.removeItem('refreshToken');
//     setIsLoggedIn(false);
//     navigate('/login');
//   };

//   return (
//     <nav className="navbar navbar-expand-lg navbar-light bg-light fixed-top">
//       <div className="container">
//         <a className="navbar-brand d-inline-flex" href="/">
//           <img className="d-inline-block" src="/assets/img/gallery/logo.svg" alt="logo" />
//           <span className="text-1000 fs-3 fw-bold ms-2 text-gradient">Rasoie</span>
//         </a>
//         <button
//           className="navbar-toggler"
//           type="button"
//           data-bs-toggle="collapse"
//           data-bs-target="#navbarSupportedContent"
//           aria-controls="navbarSupportedContent"
//           aria-expanded="false"
//           aria-label="Toggle navigation"
//         >
//           <span className="navbar-toggler-icon"></span>
//         </button>
//         <div className="collapse navbar-collapse border-top border-lg-0 my-2 mt-lg-0" id="navbarSupportedContent">
//           <div className="mx-auto pt-5 pt-lg-0 d-block d-lg-none d-xl-block">
//             <p className="mb-0 fw-bold text-lg-center">
//               <span className="fas fa-phone-volume mr-2"></span>&nbsp;
//               <a href="tel: +919591428238">+91 9282 100 000</a>
//             </p>
//           </div>

//           {isLoggedIn ? (
//             <div className="ms-lg-auto ms-xl-0">
//               <div className="dropdown">
//                 <button className="btn btn-secondary dropdown-toggle" type="button" id="userMenu" data-bs-toggle="dropdown" aria-expanded="false">
//                   {username} Gunda
//                 </button>
//                 <ul className="dropdown-menu" aria-labelledby="userMenu">
//                   <li>
//                     <Link className="dropdown-item" to="/profile">Profile</Link>
//                   </li>
//                   <li>
//                     <Link className="dropdown-item" to="/change-password">Change Password</Link>
//                   </li>
//                   <li>
//                     <button className="dropdown-item" onClick={handleLogout}>Logout</button>
//                   </li>
//                 </ul>
//               </div>
//             </div>
//           ) : (
//             <form className="d-flex mt-4 mt-lg-0 ms-lg-auto ms-xl-0">
//               <div className="input-group-icon pe-2">
//                 <i className="fas fa-search input-box-icon text-primary"></i>
//                 <input className="form-control border-0 input-box bg-100" type="search" placeholder="Search Food" aria-label="Search" />
//               </div>
//               <Link className="btn btn-white shadow-warning text-warning" to="/login">
//                 <i className="fas fa-user me-2"></i>Login
//               </Link>
//             </form>
//           )}
//         </div>
//       </div>
//     </nav>
//   );
// };

// export default Navbar;

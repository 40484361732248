// import React from 'react';
// import { Route, Routes } from 'react-router-dom';
// import Layout from './Layout';
// import CreateRestaurant from './Restaurants/Create';
// import ReadRestaurant from './Restaurants/Read';
// import UpdateRestaurant from './Restaurants/Update';
// import DeleteRestaurant from './Restaurants/Delete';
// // Import other CRUD components similarly

// const RestaurantOfficeUseRoutes = () => {
//     console.log("RestaurantOfficeUseRoutes rendered");
//   return (
//         <div style={{ display: 'flex' }}>
//       <Layout />
//       <div style={{ marginLeft: '200px', padding: '20px', flex: 1 }}>
//     <Routes>
//       <Route path="RestaurantOfficeUse/" element={<Layout />}>
//         <Route path="RestaurantOfficeUse/restaurants/create" element={<CreateRestaurant />} />
//         <Route path="RestaurantOfficeUse/restaurants/read" element={<ReadRestaurant />} />
//         <Route path="RestaurantOfficeUse/restaurants/update" element={<UpdateRestaurant />} />
//         <Route path="RestaurantOfficeUse/restaurants/delete" element={<DeleteRestaurant />} />
//         {/* Repeat for MenuCategories, MenuItems, RestaurantOfficeUsers */}
//       </Route>
//     </Routes>
//           </div>
//     </div>
//   );
// };

// export default RestaurantOfficeUseRoutes;


import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Layout from './Layout';
import CreateRestaurant from './components/Restaurants/Create';
import ReadRestaurant from './components/Restaurants/Read';
import UpdateRestaurant from './components/Restaurants/Update';
import DeleteRestaurant from './components/Restaurants/Delete';
import ListRestaurant from './components/Restaurants/List';
import CreateRestaurantType from './components/RestaurantsType/Create';
import ReadRestaurantType from './components/RestaurantsType/Read';
import UpdateRestaurantType from './components/RestaurantsType/Update';
import DeleteRestaurantType from './components/RestaurantsType/Delete';
import ListRestaurantType from './components/RestaurantsType/List';
import CreateMenuCategory from './components/MenuCategories/Create';
import ReadMenuCategory from './components/MenuCategories/Read';
import UpdateMenuCategory from './components/MenuCategories/Update';
import DeleteMenuCategory from './components/MenuCategories/Delete';
import ListMenuCategory from './components/MenuCategories/List';
import CreateMenuItem from './components/MenuItems/Create';
import ReadMenuItem from './components/MenuItems/Read';
import UpdateMenuItem from './components/MenuItems/Update';
import DeleteMenuItem from './components/MenuItems/Delete';
import ListMenuItem from './components/MenuItems/List';
import CreateRestaurantOfficeUser from './components/RestaurantOfficeUsers/Create';
import ReadRestaurantOfficeUser from './components/RestaurantOfficeUsers/Read';
import UpdateRestaurantOfficeUser from './components/RestaurantOfficeUsers/Update';
import DeleteRestaurantOfficeUser from './components/RestaurantOfficeUsers/Delete';
import ListRestaurantOfficeUser from './components/RestaurantOfficeUsers/List';

const RestaurantOfficeUseRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route path="restaurants-type/create" element={<CreateRestaurantType />} />
        <Route path="restaurants-type/list" element={<ListRestaurantType />} />
        <Route path="restaurants/create" element={<CreateRestaurant />} />
        {/* <Route path="restaurants/read" element={<ReadRestaurant />} />
        <Route path="restaurants/update" element={<UpdateRestaurant />} />
        <Route path="restaurants/delete" element={<DeleteRestaurant />} /> */}
        <Route path="restaurants/list" element={<ListRestaurant />} />
        <Route path="menu-categories/create" element={<CreateMenuCategory />} />
        {/* <Route path="menu-categories/read" element={<ReadMenuCategory />} />
        <Route path="menu-categories/update" element={<UpdateMenuCategory />} />
        <Route path="menu-categories/delete" element={<DeleteMenuCategory />} /> */}
        <Route path="menu-categories/list" element={<ListMenuCategory />} />
        <Route path="menu-items/create" element={<CreateMenuItem />} />
        {/* <Route path="menu-items/read" element={<ReadMenuItem />} />
        <Route path="menu-items/update" element={<UpdateMenuItem />} />
        <Route path="menu-items/delete" element={<DeleteMenuItem />} /> */}
        <Route path="menu-items/list" element={<ListMenuItem />} />
        <Route path="restaurant-office-users/create" element={<CreateRestaurantOfficeUser />} />
        {/* <Route path="restaurant-office-users/read" element={<ReadRestaurantOfficeUser />} />
        <Route path="restaurant-office-users/update" element={<UpdateRestaurantOfficeUser />} />
        <Route path="restaurant-office-users/delete" element={<DeleteRestaurantOfficeUser />} /> */}
        <Route path="restaurant-office-users/list" element={<ListRestaurantOfficeUser />} />
      </Route>
    </Routes>
  );
};

export default RestaurantOfficeUseRoutes;



// import React from 'react';
// import { Routes, Route } from 'react-router-dom';
// import LeftNavigation from './LeftNavigation';
// // import Restaurants from './Restaurants';
// // import MenuCategories from './MenuCategories';
// // import MenuItems from './MenuItems';
// // import RestaurantOfficeUsers from './RestaurantOfficeUsers';

// const RestaurantOfficeUseRoutes = () => {
//   return (
//     <div style={{ display: 'flex' }}>
//       <LeftNavigation />
//       <div style={{ marginLeft: '200px', padding: '20px', flex: 1 }}>
//         <Routes>
//           {/* <Route path="restaurants" element={<Restaurants />} />
//           <Route path="menu-categories" element={<MenuCategories />} />
//           <Route path="menu-items" element={<MenuItems />} />
//           <Route path="restaurant-office-users" element={<RestaurantOfficeUsers />} /> */}
//         </Routes>
//       </div>
//     </div>
//   );
// };

// export default RestaurantOfficeUseRoutes;

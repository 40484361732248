// src/utils/inactivityTracker.js

let inactivityTimeout;

export const startInactivityMonitor = (timeoutDuration = 900000, logoutCallback) => { // default 15 minutes
  const resetTimeout = () => {
    if (inactivityTimeout) {
      clearTimeout(inactivityTimeout);
    }
    inactivityTimeout = setTimeout(logoutCallback, timeoutDuration);
  };

  window.onload = resetTimeout;
  window.onmousemove = resetTimeout;
  window.onmousedown = resetTimeout;
  window.ontouchstart = resetTimeout;
  window.onclick = resetTimeout;
  window.onkeypress = resetTimeout;
  window.addEventListener('scroll', resetTimeout, true);
};

export const stopInactivityMonitor = () => {
  if (inactivityTimeout) {
    clearTimeout(inactivityTimeout);
  }
  window.onload = null;
  window.onmousemove = null;
  window.onmousedown = null;
  window.ontouchstart = null;
  window.onclick = null;
  window.onkeypress = null;
  window.removeEventListener('scroll', null, true);
};
